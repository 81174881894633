<template>
  <v-app>
    <SnackBarComponent />
    <NavigationDrawer v-if="showNavigationDrawer" />
    <v-content class="color-background">
      <Dialog />
      <router-view />
    </v-content>
    <v-footer padless>
      <v-col class="text-center primary--text" cols="12">
        © {{ new Date().getFullYear() }} —
        <strong> UP - Automação </strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import SnackBarComponent from "@/components/snackbar/SnackBar.vue";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import Dialog from "@/components/dialog/Dialog.vue";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";

export default {
  name: "App",
  components: {
    SnackBarComponent,
    NavigationDrawer,
    Dialog,
  },
  setup() {
    const authStore = useAuthStore();
    const dialogStore = useDialogStore();

    return {
      authStore,
      dialogStore,
    };
  },
  beforeCreate() {
    useAuthStore().loadLoggedUser();
  },
  computed: {
    showNavigationDrawer() {
      const isLoggedUser = localStorage.getItem("token") != null;
      const isPageValid = this.$route.name !== "ErrorView";
      return isLoggedUser && isPageValid;
    },
  },
  watch: {
    async $route(to, from) {
      if (to.fullPath != from.fullPath) window.scroll(0, 0);
      if (useAuthStore().isAdmin && to.fullPath === "/")
        this.$router.push("/home");
    },
  },
};
</script>

<style>
.color-background {
  background-color: #f2f3f3;
}
a {
  text-decoration: none;
  color: inherit;
}
</style>
