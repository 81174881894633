<template>
  <v-container fluid>
    <CardList
      :name="'Faturas'"
      :headers="headers"
      :items="objectsList"
      :loading="installmentStore.getIsLoading"
      :menu="getMenu()"
      :refresh="false"
      @action="(items, aciton) => handleAction(items, aciton)"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
import PaymentStatusEnum from "@/pages/payment/enums/PaymentStatusEnum";

import { useInstallmentStore } from "@/pages/installment/usecases/index";
import { useDueDateHistoryStore } from "@/pages/dueDateHistory/usecases/index";
import { formatDate } from "@/utils/dateUtil";


export default {
  name: "InstallmentPage",
  components: {
    CardList,
  },
  props: {
    objectsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  setup() {
    const installmentStore = useInstallmentStore();
    const dueDateHistoryStore = useDueDateHistoryStore();
    return { installmentStore, dueDateHistoryStore };
  },
  data() {
    return {
      headers: [
        { text: "Data vencimento", value: "dueDate", formatter: this.formatDate },
        { text: "Saldo devedor", value: "debtBalance" },
        { text: "Valor", align: "start", value: "installmentValue" },
        {
          text: "Status",
          value: "paymentStatus",
          formatter: this.formatPaymentStatus,
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    formatPaymentStatus(value) {
      const statusEnum = PaymentStatusEnum.values().find(
        (status) => status.cod === value
      );
      return statusEnum ? statusEnum.description : value;
    },
    handleAction(items, action) {
      const listener = this.installmentStore.getActionsListener();
      const actionFinded = listener.find((a) => a.action === action);
      if (!actionFinded) return;
      actionFinded.execute(items);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.installmentStore.getMenuActions(),
      };
    },
  },
};
</script>
