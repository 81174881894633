import { ValidateActionHandler } from '@/domain/validate/ValidateActionHandler';
export class ValidateDeleteHandler extends ValidateActionHandler {

    validate(items) {
        if (items.length === 0)
            throw new Error('É necessário selecionar um item para deletar');

        if (!window.confirm('Deseja continuar a desabilitar/deletar o item?'))
            throw new Error('Item não deletado/desabilitado');
    }
}