import PainelActions from "../enums/PainelActions"

export const painelList =
{
  id: 'painel',
  path: "/painel",
  name: "painel",
  description: "Painel de visualização",
  order: 1,
  component: () => import("@/pages/painel/PainelPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ PainelActions.DADOS_PAINEL ]
  }
}

export const painelRoutes = {
    painelList,
}
