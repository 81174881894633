<template>
  <v-container fluid>
    <CardList
      :name="'Itens'"
      :headers="headers"
      :items="objectsList"
      :loading="itemStore.getIsLoading"
      :refresh="false"
      :menu="getMenu()"
      @action="(items, aciton) => handleAction(items, aciton)"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";

import { useItemStore } from "@/pages/item/usecases/index";
import ItemActions from "@/pages/item/enums/ItemActions";

export default {
  name: "ItemPage",
  components: {
    CardList,
  },
  props: {
    objectsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  setup() {
    const itemStore = useItemStore();
    return {
      itemStore,
    };
  },
  data() {
    return {
      isUpdate: false,
      objectItem: {},
      headers: [],
    };
  },
  created() {
    if (this.itemStore.hasPermission(ItemActions.CREATE_ITEM)) {
      this.headers = [
        { text: "Item de venda", align: "start", value: "itemName" },
        { text: "Quantidade", value: "amount" },
        { text: "Preço de Custo - R$", value: "costPriceFormatted" },
        { text: "Margem", value: "margin" },
        { text: "Preço de Venda - R$", value: "salePriceFormatted" },
        { text: "Desconto", value: "discount" },
        { text: "Total", value: "total" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ];
    } else {
      this.headers = [
        { text: "Item de venda", align: "start", value: "itemName" },
        { text: "Combo", align: "start", value: "combo.name" },
        { text: "Quantidade", value: "amount" },
        { text: "Preço de Venda - R$", value: "salePriceFormatted" },
        { text: "Desconto", value: "discount" },
        { text: "Total", value: "total" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ];
    }
  },
  methods: {
    handleAction(items, action) {
      const listener = this.itemStore.getActionsListener();
      const actionFinded = listener.find((a) => a.action === action);
      if (!actionFinded) return;
      actionFinded.execute(items);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.itemStore.getMenuActions(),
      };
    },
  },
};
</script>
