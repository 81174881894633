import PermissionActions from '@/pages/permission/enums/PermissionActions'

export const permissionRouteSearchEnum =
{
  id: 'permission',
  path: "/permissao/pesquisa/:id",
  name: "permission-pesquisa",
  description: "Pesquisa parâmetro",
  order: -1,
  component: () => import("@/pages/permission/components/PermissionForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ PermissionActions.FIND_PERMISSION_BY_ID ]
  }
}

