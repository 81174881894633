import { roleRouteCreateEnum } from '@/pages/role/router/roleRouteCreate'
import { roleRouteSearchEnum } from '@/pages/role/router/roleRouteSearch'
import { roleRouteListEnum } from '@/pages/role/router/roleRouteList'


export const roleRoutes = {
    roleRouteCreateEnum,
    roleRouteSearchEnum,
    roleRouteListEnum,
}
