<template>
  <v-container fluid>
    <v-card>
      <v-row class="mx-auto w-100 my-2">
        <v-col cols="12" :md="isUpdate ? 4 : 6">
          <v-text-field
            data-cy="amount-product-item-field"
            v-model.number="itemStore.formData.amount"
            label="Quantidade"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" :md="isUpdate ? 4 : 6">
          <v-text-field
            data-cy="discount-product-item-field"
            v-model.number="itemStore.formData.discount"
            label="Desconto"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" v-if="isUpdate">
          <v-text-field
            data-cy="sequence-product-item-field"
            v-model.number="itemStore.formData.sequence"
            label="Sequencia"
            type="number"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-auto w-100 my-2" v-if="!isUpdate">
        <v-col cols="12" md="12" sm="12">
          <v-autocomplete
            v-model="itemStore.formData.combo"
            :items="combos"
            outlined
            label="Combo"
            item-text="name"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="mx-1">
            <Button
              :name="'Cancelar'"
              :handleFunction="cancel"
              :permission="true"
            />
          </div>
          <div class="mx-1 mr-4">
            <Button
              :name="'Salvar'"
              :handleFunction="save"
              :permission="actionFinded.permission"
              :outlined="false"
              :color="'primary'"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Button from "@/components/Button.vue";

import ActionEnum from "@/domain/action/ActionEnum";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { useItemStore } from "@/pages/item/usecases/index";
import { useComboStore } from "@/pages/combo/usecases/index";

export default {
  name: "ItemComboForm",
  components: {
    Button,
  },
  setup() {
    const dialogStore = useDialogStore();
    const itemStore = useItemStore();
    const comboStore = useComboStore();
    return {
      dialogStore,
      itemStore,
      comboStore,
    };
  },
  data() {
    return {
      isUpdate: useDialogStore().isUpdate,
      actionFinded: {},
      combos: [],
    };
  },
  async created() {
    let action = ActionEnum.CREATE_SAVE.action;

    if (this.isUpdate) {
      action = ActionEnum.EDIT_SAVE.action;
      const comboId = useItemStore().formData?.comboId;
      const orderId = useItemStore().formData?.ordersId;
      const productId = useItemStore().formData?.productId;

      useItemStore().formData = useDialogStore().data;

      useItemStore().setComboId(comboId);
      useItemStore().setOrderId(orderId);
      useItemStore().setProductId(productId);
    }

    const listeners = this.itemStore.getActionsListener();
    this.actionFinded = listeners.find((a) => a.action === action);
    this.combos = await this.comboStore.findAll();
  },
  methods: {
    async save() {
      this.actionFinded.execute(this.itemStore.formData);
      this.cancel();
    },
    cancel() {
      this.dialogStore.closeDialog();
    },
  },
};
</script>
