export default class OrderActions {
    static FIND_ORDER_BY_ID = "FIND_ORDER_BY_ID";
    static SUBMIT_ORDER_PDF_WEB = "SUBMIT_ORDER_PDF_WEB";
    static SUBMIT_ORDER_PDF_MOBILE = "SUBMIT_ORDER_PDF_MOBILE";
    static FIND_ORDER_ALL = "FIND_ORDER_ALL";
    static SEARCH_ORDER = "SEARCH_ORDER";
    static CREATE_ORDER = "CREATE_ORDER";
    static UPDATE_ORDER = "UPDATE_ORDER";
    static DELETE_ORDER = "DELETE_ORDER";
    static ATUALIZA_PEDIDO_PARA_ENTREGUE_COM_IMAGEM = "AtualizaPedidoParaEntregueComImagem";
    static ATUALIZA_PEDIDO_PARA_ENTREGUE_SEM_IMAGEM = "AtualizaPedidoParaEntregueSemImagem";

    static values() {
        return [
            OrderActions.FIND_ORDER_BY_ID,
            OrderActions.SUBMIT_ORDER_PDF_WEB,
            OrderActions.SUBMIT_ORDER_PDF_MOBILE,
            OrderActions.FIND_ORDER_ALL,
            OrderActions.SEARCH_ORDER,
            OrderActions.CREATE_ORDER,
            OrderActions.UPDATE_ORDER,
            OrderActions.DELETE_ORDER,
            OrderActions.ATUALIZA_PEDIDO_PARA_ENTREGUE_COM_IMAGEM,
            OrderActions.ATUALIZA_PEDIDO_PARA_ENTREGUE_SEM_IMAGEM,
        ];
    }
}

