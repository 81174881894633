<template>
  <v-container fluid>
    <v-card>
      <v-row class="mx-auto w-100 my-2">
        <v-col cols="12" md="12">
          <v-text-field
            v-model="fields.user"
            label="Usuário"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            data-cy="history-paydate-create-date-field"
            v-model="fields.creationDate"
            label="Data de criação"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            data-cy="history-paydate-old-paydate-field"
            v-model="fields.oldDueDate"
            outlined
            label="Data de vencimento antiga"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            data-cy="history-paydate-new-paydate-field"
            v-model="fields.newDueDate"
            label="Data de vencimento nova"
            outlined
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";

export default {
  name: "DueDateHistoryForm",
  components: {},
  setup() {
    const dialogStore = useDialogStore();
    return {
      dialogStore,
    };
  },
  data() {
    return {
      fields: useDialogStore().data,
    };
  },
  methods: {},
};
</script>
