import ParameterActions from '@/pages/parameter/enums/ParameterActions'

export const parameterRouteCreateEnum =
{
  id: 'parameter',
  path: "/parametro/novo",
  name: "parametro-novo",
  description: "Novo parâmetro",
  order: -1,
  component: () => import("@/pages/parameter/components/ParameterForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ParameterActions.CREATE_PARAMETER ]
  }
}
