import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import { pdfDownload } from "@/utils/support";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";

export const submitPdfOrderWeb = async (store, id) => {
  store.isLoading = true;
  try {
    const response = await axiosInstance.get(`${routes.order}/print/${id}/web`, {
      responseType: "blob",
    });
    pdfDownload(response, "pedido.pdf");
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
  } finally {
    store.isLoading = false;
  }
};