export default class StatusPedidoEnum {
  static VAZIO = { cod: null, description: "" };
  static NA_ROTA = { cod: "NA_ROTA", description: "Pedido a ser entregue" };
  static ENTREGUE = { cod: "ENTREGUE", description: "Pedido entregue" };
  static CANCELADO = { cod: "CANCELADO", description: "Pagamento cancelado" };

  static values() {
    return [
      StatusPedidoEnum.VAZIO,
      StatusPedidoEnum.NA_ROTA,
      StatusPedidoEnum.ENTREGUE,
      StatusPedidoEnum.CANCELADO,
    ];
  }
}
