import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";

export const findById = async (id) => {
    try {
        const response = await axiosInstance.get(`${routes.payment}/${id}`);
        return response.data;
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    }
};