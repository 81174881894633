import WorkOrderActions from '@/pages/workOrder/enums/workOrderActions'

export const workOrderRouteSearchEnum =
{
  id: 'workOrder',
  path: "/ordem-de-servico/pesquisa/:id",
  name: "workOrder-pesquisa",
  description: "Pesquisa ordem de serviço",
  order: -1,
  component: () => import("@/pages/workOrder/components/WorkOrderForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ WorkOrderActions.FIND_WORK_ORDER_BY_ID ]
  }
}

