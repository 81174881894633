<template>
  <v-menu bottom min-width="300px" rounded offset-y eager>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar v-bind="attrs" v-on="on">
        <v-icon color="primary" large>mdi-account-circle</v-icon>
      </v-avatar>
    </template>
    <v-card>
      <v-container>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center text-uppercase">
            <h3>{{ authStore.getName }}</h3>
            <p class="text-caption mt-1 text-uppercase">
              {{ authStore.getEmail }}
            </p>
            <v-divider class="my-3"></v-divider>
          </div>
        </v-list-item-content>
        <v-tabs v-model="tab" grow>
          <v-tab @click.stop v-if="existePermissionValida(dadosDoPerfil)">Perfil</v-tab>
          <v-tab @click.stop v-if="existePermissionValida(configs)">Configurações</v-tab>
        </v-tabs>
        <v-divider class="my-3"></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="existePermissionValida(dadosDoPerfil)">
            <v-list dense>
              <template v-for="dado in dadosDoPerfil">
                <v-list-item
                  @click="dado.action"
                  :to="dado?.to"
                  :action="dado?.action"
                  v-if="dado.permission"
                  :key="dado"
                >
                  <v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="primary" class="mx-3">{{
                        dado.icon
                      }}</v-icon>
                      <v-list-item-title class="primary--text text-body-2">{{
                        dado.title
                      }}</v-list-item-title>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="dado"></v-divider>
              </template>
            </v-list>
          </v-tab-item>
          <v-tab-item v-if="existePermissionValida(configs)">
            <v-list dense>
              <template v-for="config in configs">
                <v-list-item
                  :to="config.to"
                  v-if="config.permission"
                  :key="config"
                >
                  <v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="primary" class="mx-3">{{
                        config.icon
                      }}</v-icon>
                      <v-list-item-title class="primary--text text-body-2">{{
                        config.title
                      }}</v-list-item-title>
                    </v-list-item-icon>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="config"></v-divider>
              </template>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { useAuthStore } from "@/pages/auth/usecases/index";
import { usePerfilStore } from "@/components/profile/usecases/index";
import { useUserStore } from "@/pages/user/usecases/index";

export default {
  name: "ProfileComponent",
  setup() {
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const perfilStore = usePerfilStore();
    return { authStore, userStore, perfilStore };
  },
  data() {
    return {
      items: ["Usuário", "Configurações"],
      tab: null,
      configs: usePerfilStore().configuracoesDoPerfil(),
      dadosDoPerfil: usePerfilStore().dadosDoPerfil(),
    };
  },
  methods: {
    existePermissionValida(data) {
      return data.some((item) => item.permission);
    },
  },
};
</script>
