import UserActions from '@/pages/user/enums/UserActions'

export const userRouteSearchEnum = {
    id: 'user',
    path: "/usuarios/pesquisa/:id",
    name: "users-pesquisa",
    description: 'Pesquisa usuário',
    order: -1,
    component: () => import("@/pages/user/components/UserForm.vue"),
    meta: {
        requiresAuth: true,
        requiresPermissions: [UserActions.FIND_USER_BY_ID, UserActions.FIND_USER_CESTA_BY_ID]
    }
}