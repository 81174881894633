import { defineStore } from "pinia";
import axiosInstance from "@/configs/axios";
import { routes } from "@/router/routes";
import { useAuthStore } from "@/pages/auth/usecases/index";
import PainelActions from "@/pages/painel/enums/PainelActions";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";

export const usePainelStore = defineStore("painelStore", {
  state: () => ({
    painelData: {},
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    authorities: [],
  }),
  actions: {
    async carregaDadosPainel(params) {
      this.isLoading = true;
      try {
        const response = await axiosInstance.get(routes.painel, {
          params: {
            funcionarioId: params?.funcionario?.id,
            dataInicio: params?.dataInicio,
            dataFim: params?.dataFim,
          },
        });
        this.painelData = response.data;
        this.listLoaded = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      } finally {
        this.isLoading = false;
      }
    },
    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(PainelActions.DADOS_PAINEL);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    getMenuActions() {
      return [];
    },
    getActionsListener() {
      return [...this.getMenuActions()];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getDadosPainel() {
      return this.$state.painelData;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
