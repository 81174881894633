import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import router from "@/router/index";
import axiosInstance from "@/configs/axios";

export const create = async (store) => {
    if (store.processing) return;

    store.processing = true;
    store.isLoading = true;
    try {
        console.log(store.$state.formData)
        await axiosInstance.post(`${routes.customer}/cesta`, store.$state.formData);
        await store.refresh();
        useSnackBarStore().mostrarSnackBar(
            messageSuccess(`Cliente cadastrado com sucesso!`)
        );
        store.closeModal = true;
        router.back()
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        store.closeModal = false;
    } finally {
        store.isLoading = false;
        store.processing = false;
    }
};