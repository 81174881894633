import { defineStore } from "pinia";
import router from "@/router/index";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";

import ItemForm from "@/pages/item/components/ItemForm.vue";
import ItemComboForm from "@/pages/item/components/ItemComboForm.vue";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useComboStore } from "@/pages/combo/usecases/index";
import { useOrderStore } from "@/pages/order/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import ItemActions from "@/pages/item/enums/ItemActions";
import NextIdGeneration from "@/domain/NextIdGeneration";

import * as createAction from "@/pages/item/usecases/createAction.js";
import * as findByIdAction from "@/pages/item/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/item/usecases/updateByIdAction.js";
import * as deleteAllByIdAction from "@/pages/item/usecases/deleteAllByIdAction.js";

export const useItemStore = defineStore("itemStore", {
  state: () => ({
    formData: {
      id: null,
      product: null,
      combo: null,
      amount: null,
      costPriceFormated: null,
      margin: null,
      salePriceFormated: null,
      discountFormated: null,
      discount: 0,
      comboId: null,
      productId: null,
      ordersId: null,
      sequence: null,
      total: null,
      price: 0,
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async createItem() {
      await createAction.create(this);
    },
    async findById(id) {
      return await findByIdAction.findById(id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    setComboId(comboId) {
      console.log(comboId);
      this.formData.comboId = comboId;
    },

    setOrderId(orderId) {
      this.formData.ordersId = orderId;
    },

    setProductId(productId) {
      this.formData.productId = productId;
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(ItemActions.FIND_ALL_ITEM);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    isRouteCombo() {
      const route = router.getRouteFromRouteName(router.history.current.name);
      return route.id === "combo";
    },

    addItems(item, items, store) {
      item.id = new NextIdGeneration(items).nextId();
      item.itemName = item.product?.name || item.combo?.name;
      store.addItems(JSON.parse(JSON.stringify(item)));
    },

    cleanData() {
      this.formData.id = null;
      this.formData.amount = null;
      this.formData.costPriceFormated = null;
      this.formData.margin = null;
      this.formData.salePriceFormated = null;
      this.formData.discountFormated = null;
      this.formData.discount = 0;
      this.formData.sequence = null;
      this.formData.total = null;
      this.formData.price = 0;
      this.formData.product = null;
      this.formData.combo = null;
    },

    resetIds() {
      this.formData.comboId = null;
      this.formData.productId = null;
      this.formData.ordersId = null;
    },

    actionCreate() {
      const action = new Action(
        "Criar produto de venda avulsa",
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(ItemActions.CREATE_ITEM),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          useDialogStore().openDialogWithData({
            title: "Cria item avulso",
            childComponent: ItemForm,
            isUpdate: false,
          });
        }
      );

      if (this.isRouteCombo()) action.name = "Criar item de produto";

      return action;
    },

    actionCreateCombo() {
      const action = new Action(
        "Criar produto de venda por combo",
        ActionEnum.CREATE.icon,
        "createCombo",
        this.hasPermission(ItemActions.CREATE_ITEM),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          useDialogStore().openDialogWithData({
            title: "Cria item de combo",
            childComponent: ItemComboForm,
            isUpdate: false,
          });
        }
      );

      if (this.isRouteCombo()) return {};

      return action;
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(ItemActions.FIND_ALL_ITEM),
        new ValidateEditHandler(),
        async (items) => {
          useDialogStore().openDialogWithData({
            title: "Edita item",
            childComponent: ItemForm,
            isUpdate: true,
            data: items[0],
          });
        }
      );
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        this.hasPermission(ItemActions.DELETE_ITEM),
        new ValidateDeleteHandler(),
        (items) => {
          if (this.isRouteCombo()) {
            if (this.formData.comboId) {
              this.deleteAllById(items, useComboStore().formData.items);
            } else {
              items.forEach((item) => {
                const findObject = useComboStore().formData.items.find(
                  (element) => element.id === item.id
                );
                useComboStore().formData.items.splice(
                  useComboStore().formData.items.indexOf(findObject),
                  1
                );
              });
            }
          } else {
            if (this.formData.ordersId) {
              this.deleteAllById(items, useOrderStore().formData.items);
            } else {
              items.forEach((item) => {
                const findObject = useOrderStore().formData.items.find(
                  (element) => element.id === item.id
                );
                useOrderStore().formData.items.splice(
                  useOrderStore().formData.items.indexOf(findObject),
                  1
                );
              });
            }
          }
        }
      );
    },

    actionCreateSaveCombo() {
      const action = new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(ItemActions.CREATE_ITEM),
        new ValidateActionHandler(),
        (item) => {
          this.formData.productId = item.product?.id;
          if (this.formData.comboId) {
            this.createItem();
          } else {
            this.addItems(
              item,
              useComboStore().formData.items,
              useComboStore()
            );
          }
          this.resetIds();
        }
      );
      return this.isRouteCombo() ? action : {};
    },

    actionCreateSaveOrders() {
      const action = new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(ItemActions.CREATE_ITEM),
        new ValidateActionHandler(),
        (item) => {
          this.formData.productId = item.product?.id;
          this.formData.comboId = item.combo?.id;

          if (this.formData.ordersId) {
            this.createItem();
          } else {
            this.addItems(
              item,
              useOrderStore().formData.items,
              useOrderStore()
            );
          }
          this.resetIds();
        }
      );
      return this.isRouteCombo() ? {} : action;
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(ItemActions.UPDATE_ITEM),
        new ValidateActionHandler(),
        (item) => {
          let findObject = {};
          if (this.isRouteCombo()) {
            findObject = useComboStore().formData.items.find(
              (element) => element.id === item.id
            );
            if (!findObject) {
              useSnackBarStore().mostrarSnackBar(
                messageError(
                  error(
                    "Não foi possível encontrar esse registro, contate o suporte."
                  )
                )
              );
              return;
            }
            if (this.formData.comboId) this.updateById();
          } else {
            findObject = useOrderStore().formData.items.find(
              (element) => element.id === item.id
            );
            if (!findObject) {
              useSnackBarStore().mostrarSnackBar(
                messageError(
                  error(
                    "Não foi possível encontrar esse registro, contate o suporte."
                  )
                )
              );
              return;
            }
            if (this.formData.ordersId) this.updateById();
          }
        }
      );
    },

    getMenuActions() {
      return [
        this.actionCreateCombo(),
        this.actionCreate(),
        this.actionEdit(),
        this.actionDelete(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionCreateSaveCombo(),
        this.actionCreateSaveOrders(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
