import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { stockRoutes } from "@/pages/stock/router/index";
import StockActions from "@/pages/stock/enums/stockActions";

import * as createAction from "@/pages/stock/usecases/createAction";
import * as findPaginateAction from "@/pages/stock/usecases/findPaginateAction";
import * as findAllAction from "@/pages/stock/usecases/findAllAction";
import * as findByIdAction from "@/pages/stock/usecases/findByIdAction";
import * as updateByIdAction from "@/pages/stock/usecases/updateByIdAction";
import * as searchAction from "@/pages/stock/usecases/searchAction";

export const useStockStore = defineStore("stockStore", {
  state: () => ({
    formData: {
      id: null,
      productId: null,
      costPrice: null,
      amount: null,
      amountEntry: null,
      entryDate: null,
      product: {},
      productCostPrice: null,
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById(fields) {
      await updateByIdAction.updateById(this, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    refresh() {
      this.findPaginate({ page: this.getCurrentPage }, true);
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(StockActions.FIND_ALL_STOCK);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    cleanData() {
      this.formData.id = null;
      this.formData.productId = null;
      this.formData.costPrice = null;
      this.formData.amount = null;
      this.formData.amountEntry = null;
      this.formData.entryDate = null;
      this.formData.product = {};
      this.formData.productCostPrice = null;
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(StockActions.CREATE_STOCK),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          router.push(
            router.getPathFromRouteName(stockRoutes.stockRouteCreateEnum.name),
            null,
            () => {}
          );
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(StockActions.FIND_ALL_STOCK),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(stockRoutes.stockRouteSearchEnum.name) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(StockActions.SEARCH_STOCK),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              stockRoutes.stockRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(StockActions.FIND_ALL_STOCK),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(StockActions.FIND_ALL_STOCK),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(StockActions.CREATE_STOCK),
        new ValidateActionHandler(),
        () => {
          this.formData.productId = this.formData.product?.id;
          this.create();
        }
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(StockActions.UPDATE_STOCK),
        new ValidateActionHandler(),
        (items) => {
          this.updateById(items);
        }
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(StockActions.SEARCH_STOCK),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    getMenuActions() {
      return [this.actionCreate(), this.actionEdit(), this.actionFilter()];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
