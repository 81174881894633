import ContactActions from '@/pages/contact/enums/ContactActions'

export const contactRouteSearchEnum = {
    id: 'contact',
    path: "/contatos/pesquisa/:id",
    name: "contact-pesquisa",
    description: 'Pesquisa contato',
    order: -1,
    component: () => import("@/pages/contact/components/ContactForm.vue"),
    meta: {
        requiresAuth: true,
        requiresPermissions: [ContactActions.FIND_CONTACT_BY_ID]
    }
}