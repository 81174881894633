<template>
  <v-menu v-model="menuDate" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formatDate(value)"
        :label="label"
        append-icon="mdi-calendar-month"
        readonly
        outlined
        v-on="on"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      @input="handleDateChange"
      style="width: 100%"
      :disabled="disabled"
      locale="pt-BR"
      :min="minDate"
      :max="maxDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate } from "@/utils/dateUtil";
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menuDate: false,
      selectedDate: this.value,
    };
  },
  methods: {
    handleDateChange(value) {
      this.selectedDate = value;
      this.$emit("input", value);
      this.menuDate = false;
    },
    formatDate(value) {
      if(value === null)
        return value
      return formatDate(value)
    },
  },
};
</script>