import RegionActions from '@/pages/region/enums/RegionActions'

export const regionRouteSearchEnum =
{
  id: 'region',
  path: "/regiao/pesquisa/:id",
  name: "region-pesquisa",
  description: "Pesquisa região",
  order: -1,
  component: () => import("@/pages/region/components/RegionForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ RegionActions.FIND_REGION_BY_ID ]
  }
}

