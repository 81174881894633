import { defineStore } from "pinia";

import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import DueDateHistoryForm from "@/pages/dueDateHistory/components/DueDateHistoryForm.vue";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import * as findByIdAction from "@/pages/customer/usecases/findByIdAction.js";

export const useDueDateHistoryStore = defineStore("dueDateHistoryStore", {
  state: () => ({
    formData: {
      id: null,
      createDate: null,
      oldPayDate: null,
      newPayDate: null,
      user: {},
    },
    authorities: [],
  }),
  actions: {
    async findById(id) {
      return await findByIdAction.findById(this, id);
    },
    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        true,
        new ValidateEditHandler(),
        async (items) => {
          useDialogStore().openDialogWithData({
            title: "Histórico de vencimento",
            childComponent: DueDateHistoryForm,
            isUpdate: true,
            data: items[0],
          });
        }
      );
    },

    getMenuActions() {
      return [this.actionEdit()];
    },

    getActionsListener() {
      return [...this.getMenuActions()];
    },
  },
  getters: {
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
