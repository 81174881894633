<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto">
          <v-card-title>Cadastrar Imagem</v-card-title>
          <v-card-text>
            <v-file-input
              label="Selecione uma imagem"
              v-model="imageStore.formData.file"
              accept="image/*"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" class="d-flex justify-end">
                <div class="mx-1">
                  <Button
                    :name="'Limpar'"
                    :handleFunction="clean"
                    :permission="true"
                  />
                </div>
                <div class="mx-1 mr-4">
                  <Button
                    :name="'Salvar'"
                    :handleFunction="save"
                    :permission="true"
                    :outlined="false"
                    :color="'primary'"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ImageViewer
      :images="this.images"
      @action="(item, action) => handleAction(item, action)"
      :permission="true"
    ></ImageViewer>
  </v-container>
</template>

<script>
import { useImageStore } from "@/components/image/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";

import ActionEnum from "@/domain/action/ActionEnum";
import Button from "@/components/Button.vue";
import ImageViewer from "@/components/image/ImageViewer.vue";

export default {
  name: "ImageForm",
  components: {
    Button,
    ImageViewer,
  },
  setup() {
    const imageStore = useImageStore();
    const dialogStore = useDialogStore();
    return {
      imageStore,
      dialogStore,
    };
  },
  data() {
    return {
      images: [],
      listeners: [],
      actionFinded: {},
    };
  },

  created() {
    this.listeners = useImageStore().getActionsListener();
    const data = useDialogStore().data;
    this.images = data.files;
  },

  methods: {
    async handleAction(item, action) {
      this.actionFinded = this.listeners.find((a) => a.action === action);
      if (!this.actionFinded) return;
      this.actionFinded.execute(item);
    },
    async save() {
      const action = ActionEnum.CREATE_SAVE.action;
      this.actionFinded = this.imageStore
        .getActionsListener()
        .find((a) => a.action === action);
      if (!this.actionFinded) return;
      this.actionFinded.execute(this.imageStore.formData.file);
      this.clean();
    },
    async clean() {
      useDialogStore().closeDialog();
      this.imageStore.cleanData();
    },
  },
};
</script>
