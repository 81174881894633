export default class ActionEnum {
    static ACTION = { name: 'Ação', icon: '', action: 'action' };
    static CREATE = { name: 'Criar', icon: 'mdi-plus-box-outline', action: 'create' };
    static DELETE = { name: 'Excluir', icon: 'mdi-delete', action: 'delete' };
    static DISABLE = { name: 'Desabilitar', icon: 'mdi-close-circle', action: 'disable' };
    static EDIT = { name: 'Editar', icon: 'mdi-pencil', action: 'edit' };
    static FILTER = { name: 'Filtro', icon: 'mdi-filter', action: 'filter' };
    static PAGINATE = { name: 'Paginação', icon: '', action: 'paginate' };
    static REFRESH = { name: 'Atualizar', icon: 'mdi-refresh', action: 'refresh' };
    static CREATE_SAVE = { name: 'Salvar criação', icon: '', action: 'saveCreate' };
    static EDIT_SAVE = { name: 'Salvar atualização', icon: '', action: 'saveUpdate' };
    static SEARCH = { name: 'Pesquisar', icon: '', action: 'search' };
    static PRINTER = { name: 'Imprimir pdf', icon: 'mdi-printer', action: 'printer' };
    static SELECTED = { name: 'Listagem selecionada', icon: '', action: 'selected' };

    static values() {
      return [
        ActionEnum.ACTION,
        ActionEnum.CREATE,
        ActionEnum.DELETE,
        ActionEnum.DISABLE,
        ActionEnum.EDIT,
        ActionEnum.FILTER,
        ActionEnum.PAGINATE,
        ActionEnum.REFRESH,
        ActionEnum.CREATE_SAVE,
        ActionEnum.EDIT_SAVE,
        ActionEnum.SEARCH,
        ActionEnum.PRINTER,
        ActionEnum.SELECTED,
      ];
    }
  }
  
  