import RegionActions from '@/pages/region/enums/RegionActions'

export const regionRouteSearchParametersEnum =
{
  id: 'region',
  path: "/regiao/pesquisa",
  name: "region-search-parameters",
  description: "Pesquisa região com parâmetros",
  order: -1,
  component: () => import("@/pages/region/components/RegionFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ RegionActions.SEARCH_REGION ]
  }
}

