export default class ItemActions {
    static FIND_ITEM_BY_ID = "FIND_ITEM_BY_ID";
    static FIND_ALL_ITEM = "FIND_ALL_ITEM";
    static CREATE_ITEM = "CREATE_ITEM";
    static UPDATE_ITEM = "UPDATE_ITEM";
    static DELETE_ITEM = "DELETE_ITEM";

    static values() {
        return [
            ItemActions.FIND_ITEM_BY_ID,
            ItemActions.FIND_ALL_ITEM,
            ItemActions.CREATE_ITEM,
            ItemActions.UPDATE_ITEM,
            ItemActions.DELETE_ITEM,
        ];
    }
}

