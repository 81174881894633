import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { useRegionStore } from "@/pages/region/usecases/index";
import { messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";

export const findById = async (store, id) => {
    store.isLoading = true;
    try {
        const response = await axiosInstance.get(`${routes.user}/${id}`);
        const user = response.data;
        user.regions = await useRegionStore().findAllByUser(user.id)
        return user;
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    } finally {
        store.isLoading = false;
    }
}