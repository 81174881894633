export default class InstallmentActions {
    static FIND_INSTALLMENT_BY_ID = "FIND_INSTALLMENT_BY_ID";
    static UPDATE_INSTALLMENT = "UPDATE_INSTALLMENT";

    static values() {
        return [
            InstallmentActions.FIND_INSTALLMENT_BY_ID,
            InstallmentActions.UPDATE_INSTALLMENT,
        ];
    }
}

