import { productRouteCreateEnum } from '@/pages/product/router/productRouteCreate'
import { productRouteSearchEnum } from '@/pages/product/router/productRouteSearch'
import { productRouteListEnum } from '@/pages/product/router/productRouteList'
import { productRouteSearchParametersEnum } from '@/pages/product/router/productRouteSearchParameters'


export const productRoutes = {
    productRouteCreateEnum,
    productRouteSearchEnum,
    productRouteListEnum,
    productRouteSearchParametersEnum,
}
