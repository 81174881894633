export default class PermissionActions {
    static FIND_PERMISSION_BY_ID = "FIND_PERMISSION_BY_ID";
    static FIND_AUTHORITIES_BY_USER = "FIND_AUTHORITIES_BY_USER";
    static FIND_ALL_PERMISSION = "FIND_ALL_PERMISSION";
    static CREATE_PERMISSION = "CREATE_PERMISSION";
    static UPDATE_PERMISSION = "UPDATE_PERMISSION";
    static DELETE_PERMISSION = "DELETE_PERMISSION";

    static values() {
        return [
            PermissionActions.FIND_PERMISSION_BY_ID,
            PermissionActions.FIND_AUTHORITIES_BY_USER,
            PermissionActions.FIND_ALL_PERMISSION,
            PermissionActions.CREATE_PERMISSION,
            PermissionActions.UPDATE_PERMISSION,
            PermissionActions.DELETE_PERMISSION,
        ];
    }
}

