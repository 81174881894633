import { stockRouteCreateEnum } from '@/pages/stock/router/stockRouteCreate'
import { stockRouteSearchEnum } from '@/pages/stock/router/stockRouteSearch'
import { stockRouteListEnum } from '@/pages/stock/router/stockRouteList'
import { stockRouteSearchParametersEnum } from '@/pages/stock/router/stockRouteSearchParameters'


export const stockRoutes = {
    stockRouteCreateEnum,
    stockRouteSearchEnum,
    stockRouteListEnum,
    stockRouteSearchParametersEnum,
}
