export default class RegionActions {
    static FIND_REGION_BY_ID = 'FIND_REGION_BY_ID';
    static FIND_ALL_REGION = 'FIND_ALL_REGION';
    static FIND_ALL_REGION_BY_USER = 'FIND_ALL_REGION_BY_USER';
    static CREATE_REGION = 'CREATE_REGION';
    static UPDATE_REGION = 'UPDATE_REGION';
    static UPDATE_REGION_LOTE = 'UPDATE_REGION_LOTE';
    static DELETE_REGION = 'DELETE_REGION';
    static SEARCH_REGION = 'SEARCH_REGION';

    static values() {
        return [
            RegionActions.FIND_REGION_BY_ID,
            RegionActions.FIND_ALL_REGION,
            RegionActions.FIND_ALL_REGION_BY_USER,
            RegionActions.CREATE_REGION,
            RegionActions.UPDATE_REGION,
            RegionActions.UPDATE_REGION_LOTE,
            RegionActions.DELETE_REGION,
            RegionActions.SEARCH_REGION,
        ];
    }
}

