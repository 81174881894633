import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { productRoutes } from "@/pages/product/router/index";
import productActions from "@/pages/product/enums/productActions";

import * as createAction from "@/pages/product/usecases/createAction";
import * as findPaginateAction from "@/pages/product/usecases/findPaginateAction";
import * as findAllAction from "@/pages/product/usecases/findAllAction";
import * as findByIdAction from "@/pages/product/usecases/findByIdAction";
import * as updateByIdAction from "@/pages/product/usecases/updateByIdAction";
import * as deleteByIdAction from "@/pages/product/usecases/deleteByIdAction";
import * as deleteAllByIdAction from "@/pages/product/usecases/deleteAllByIdAction";
import * as searchAction from "@/pages/product/usecases/searchAction";

export const useProductStore = defineStore("productStore", {
  state: () => ({
    id: "",
    name: "",
    costPrice: "",
    salePrice: "",
    costPriceFormated: "",
    margin: "",
    salePriceFormated: "",
    quantityStock: "",
    list: [],
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create(fields) {
      await createAction.create(this, fields);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById(fields) {
      await updateByIdAction.updateById(this, fields);
    },

    async deleteById(field, fields) {
      await deleteByIdAction.deleteById(this, field, fields);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    async refresh() {
      await this.findPaginate({ page: this.getCurrentPage }, true);
    },

    setStore(fields) {
      this.id = fields.id;
      this.name = fields.name;
      this.costPrice = fields.costPrice;
      this.salePrice = fields.salePrice;
      this.costPriceFormated = fields.costPriceFormated;
      this.margin = fields.margin;
      this.salePriceFormated = fields.salePriceFormated;
      this.quantityStock = fields.quantityStock;
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(productActions.FIND_ALL_PRODUCT);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(productActions.CREATE_PRODUCT),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              productRoutes.productRouteCreateEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(productActions.FIND_ALL_PRODUCT),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(
              productRoutes.productRouteSearchEnum.name
            ) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionDisable() {
      return new Action(
        ActionEnum.DISABLE.name,
        ActionEnum.DISABLE.icon,
        ActionEnum.DISABLE.action,
        this.hasPermission(productActions.DELETE_PRODUCT),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, this.getList)
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(productActions.SEARCH_PRODUCT),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              productRoutes.productRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(productActions.FIND_ALL_PRODUCT),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(productActions.FIND_ALL_PRODUCT),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(productActions.CREATE_PRODUCT),
        new ValidateActionHandler(),
        (items) => this.create(items)
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(productActions.UPDATE_PRODUCT),
        new ValidateActionHandler(),
        (items) => this.updateById(items)
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(productActions.SEARCH_PRODUCT),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDisable(),
        this.actionFilter(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
