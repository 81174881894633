import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";

export class ValidateEditHandler extends ValidateActionHandler {
  constructor(mensagem = "É necessário selecionar um item para editar") {
    super();
    this.mensagem = mensagem;
  }

  validate(items) {
    if (items.length === 0) throw new Error(this.mensagem);
    if (items.length > 1)
      throw new Error("Não é permitido mais de um registro");
  }
}
