export default class StockActions {
    static FIND_STOCK_BY_ID = "FIND_STOCK_BY_ID";
    static FIND_ALL_STOCK = "FIND_ALL_STOCK";
    static CREATE_STOCK = "CREATE_STOCK";
    static UPDATE_STOCK = "UPDATE_STOCK";
    static SEARCH_STOCK = "SEARCH_STOCK";

    static values() {
        return [
            StockActions.FIND_STOCK_BY_ID,
            StockActions.FIND_ALL_STOCK,
            StockActions.CREATE_STOCK,
            StockActions.UPDATE_STOCK,
            StockActions.SEARCH_STOCK,
        ];
    }
}

