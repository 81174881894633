import { defineStore } from "pinia";
import axiosInstance from "@/configs/axios";
import { routes } from "@/router/routes";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { useCustomerStore } from "@/pages/customer/usecases/index";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import log from "loglevel";

import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";

export const useImageStore = defineStore("imageStore", {
  state: () => ({
    formData: {
      id: null,
      originalName: null,
      contentType: null,
      path: null,
      personId: null,
      person: [],
      file: {},
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    authorities: [],
  }),
  actions: {
    async create() {
      if (this.processing) return;

      this.processing = true;
      const formData = this.formData
      try {
        const formDataSubmit = new FormData();
        formDataSubmit.append("file", formData.file);
        formDataSubmit.append("personId", formData.personId);

        await axiosInstance.post(`${routes.customer}/carrega/imagem`, formDataSubmit, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Imagem enviada com sucesso`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async findAll(personId) {
      try {
        const response = await axiosInstance.get(routes.picture, {
          params: {
            personId: personId,
          },
        });
        this.list = response.data.content;
        this.listLoaded = true;
      } catch (e) {
        log.error(e);
      }
    },

    async findById(id) {
      try {
        const response = await axiosInstance.get(`${routes.picture}/${id}`);
        return response.data;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      }
    },

    async deleteImage(object, arrayObjects) {
      if (this.processing) return;

      this.processing = true;
      try {
        await axiosInstance.delete(`${routes.picture}/${object.id}`);
        arrayObjects.splice(arrayObjects.indexOf(object), 1);
        await this.findAll();
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Imagem deletada com sucesso!`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(CustomerActions.FIND_ALL_CUSTOMER)
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission)
    },

    setPersonId(id) {
      this.formData.personId = id;
    },

    cleanData() {
      this.formData.id = null;
      this.formData.originalName = null;
      this.formData.contentType = null;
      this.formData.path = null;
      this.formData.person = [];
      this.formData.file = {};
    },

    resetIds() {
      this.formData.personId = null;
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        true,
        new ValidateDeleteHandler(),
        (items) => {
          this.deleteImage(items, useCustomerStore().formData.files)
        }
      )
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        true,
        new ValidateActionHandler(),
        () => {
          this.create()
        }
      )
    },

    getActionsListener() {
      return [
        this.actionCreateSave(),
        this.actionDelete(),
      ];
    },

  },
  getters: {
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList: (state) => (personId) => {
      if (!state.listLoaded) {
        state.findAll(personId);
      }
      return state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities.filter(a => a.includes('FILE'))
      return this.$state.authorities;
    },
  },
});
