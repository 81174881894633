import OrderActions from '@/pages/order/enums/OrderActions'

export const orderRouteSearchEnum =
{
  id: 'order',
  path: "/pedido/pesquisa/:id",
  name: "order-pesquisa",
  description: "Pesquisa pedido",
  order: -1,
  component: () => import("@/pages/order/components/OrderForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ OrderActions.FIND_ORDER_BY_ID ]
  }
}

