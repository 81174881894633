import { permissionRouteCreateEnum } from '@/pages/permission/router/permissionRouteCreate'
import { permissionRouteSearchEnum } from '@/pages/permission/router/permissionRouteSearch'
import { permissionRouteListEnum } from '@/pages/permission/router/permissionRouteList'


export const permissionRoutes = {
    permissionRouteCreateEnum,
    permissionRouteSearchEnum,
    permissionRouteListEnum,
}
