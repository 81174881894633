export const authRoutes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/pages/auth/LoginPage.vue"),
    meta: {
      requiresAuth: false,
      requiresAdmin: false
    }
  },
];