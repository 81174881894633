export function pdfDownload(response, nameFile) {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nameFile);
  document.body.appendChild(link);
  link.click();
}

export function notExists(object) {
  return (
    object === undefined ||
    object === null ||
    object === "" ||
    Object.keys(object).length === 0
  );
}

export function processaDadosDialogComCallback(data, callback) {
  if (Array.isArray(data)) {
    return Promise.all(data.map(item => callback(item)));
  } else {
    return callback(data);
  }
}

export function processaDadosDialog(data) {
  if (Array.isArray(data)) {
    return data[0];
  } else {
    return data;
  }
}