<template>
  <v-row class="mx-auto w-100 my-2">
    <v-col cols="12" md="8" sm="12">
      <v-text-field
        v-model="addressStore.formData.propriedade"
        label="Propriedade"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <v-text-field
        v-model="addressStore.formData.observacao"
        label="Observação"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <v-text-field
        data-cy="address-latitude-field"
        v-model="addressStore.formData.latitude"
        label="Latitude"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <v-text-field
        data-cy="address-longitude-field"
        v-model="addressStore.formData.longitude"
        label="Longitude"
        outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <v-autocomplete
        v-model="addressStore.formData.region"
        :items="regions"
        outlined
        label="Região"
        :no-data-text="'Nenhum item encontrado.'"
        item-text="name"
        append-icon="mdi-map-marker"
        return-object
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <v-autocomplete
        data-cy="address-city-field"
        v-model="addressStore.formData.city"
        :no-data-text="'Nenhum item encontrado.'"
        :items="cityStore.getList"
        item-text="name"
        return-object
        label="Cidade"
        outlined
      ></v-autocomplete>
    </v-col>
    <v-col cols="12">
      <v-textarea
        data-cy="address-addressInformation-field"
        v-model="addressStore.formData.addressInformation"
        label="Complemento"
        outlined
      ></v-textarea>
    </v-col>
    <v-col cols="12" class="d-flex justify-end">
      <div class="mx-1">
        <Button
          :name="'Cancelar'"
          :handleFunction="cancel"
          :permission="true"
        />
      </div>
      <div class="mx-1 mr-4">
        <Button
          :name="'Salvar'"
          :handleFunction="save"
          :permission="actionFinded.permission"
          :outlined="false"
          :color="'primary'"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { useAddressStore } from "@/pages/address/usecases/index";
import { useCityStore } from "@/pages/city/usecases/index";
import { useRegionStore } from "@/pages/region/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import ActionEnum from "@/domain/action/ActionEnum";

import Button from "@/components/Button.vue";

export default {
  name: "EnderecoRuralForm",
  components: {
    Button,
  },
  setup() {
    const addressStore = useAddressStore();
    const cityStore = useCityStore();
    const regionStore = useRegionStore();
    return {
      addressStore,
      cityStore,
      regionStore,
    };
  },
  data() {
    return {
      isUpdate: useDialogStore().isUpdate,
      actionFinded: {},
      regions: [],
    };
  },
  async created() {
    let action = ActionEnum.CREATE_SAVE.action;

    if (this.isUpdate) {
      const personId = useAddressStore().formData.personId;
      useAddressStore().formData = useDialogStore().data;
      useAddressStore().setPersonId(personId);
      action = ActionEnum.EDIT_SAVE.action;
    }

    const listeners = this.addressStore.getActionsListener();
    this.actionFinded = listeners.find((a) => a.action === action);
    this.regions = await this.regionStore.findAll();
  },
  methods: {
    async save() {
      useAddressStore().setTipoEnderecoRural();
      this.actionFinded.execute(useAddressStore().formData);
      this.cancel();
    },
    cancel() {
      useDialogStore().closeDialog();
    },
  },
};
</script>
