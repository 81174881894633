<template>
  <v-container fluid>
    <CardList
      :name="'Históricos de vencimentos'"
      :headers="headers"
      :items="dialogStore.data"
      :refresh="false"
      :menu="getMenu()"
      @action="(items, aciton) => handleAction(items, aciton)"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
import { useDueDateHistoryStore } from "@/pages/dueDateHistory/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { formatDate } from "@/utils/dateUtil";

export default {
  name: "DueDateHistoryPage",
  components: {
    CardList,
  },
  setup() {
    const dueDateHistoryStore = useDueDateHistoryStore();
    const dialogStore = useDialogStore();
    return { dueDateHistoryStore, dialogStore };
  },
  data() {
    return {
      headers: [
        { text: "Usuário", align: "start", value: "user.person.name" },
        { text: "Data de criação", value: "creationDate", formatter: this.formatDate },
        { text: "Data de vencimento antiga", value: "oldDueDate", formatter: this.formatDate },
        { text: "Data de vencimento nova", value: "newDueDate", formatter: this.formatDate },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    handleAction(items, action) {
      const listener = this.dueDateHistoryStore.getActionsListener();
      const actionFinded = listener.find((a) => a.action === action);
      if (!actionFinded) return;
      actionFinded.execute(items);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: [],
      };
    },
  },
};
</script>
