import ProductActions from '@/pages/product/enums/productActions'

export const productRouteSearchEnum =
{
  id: 'product',
  path: "/produto/pesquisa/:id",
  name: "product-pesquisa",
  description: "Pesquisa produto",
  order: -1,
  component: () => import("@/pages/product/components/ProductForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ProductActions.FIND_PRODUCT_BY_ID ]
  }
}

