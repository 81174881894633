<template>
  <v-container fluid>
    <h3>Escolha o tipo de endereço</h3>
    <div>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="selectedAddressType" row>
            <v-radio
              label="Endereço Residencial"
              value="residencial"
              v-if="isEnderecoResidencialValido"
            ></v-radio>
            <v-radio
              label="Endereço Rural"
              value="rural"
              v-if="isEnderecoRuralValido"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="
        selectedAddressType === 'residencial' && isEnderecoResidencialValido
      "
    >
      <AddressForm />
    </div>
    <div v-if="selectedAddressType === 'rural' && isEnderecoRuralValido">
      <EnderecoRuralForm />
    </div>
  </v-container>
</template>

<script>
import AddressForm from "@/pages/address/components/AddressForm.vue";
import EnderecoRuralForm from "@/pages/address/components/EnderecoRuralForm.vue";
import AddressActions from "@/pages/address/enums/AddressActions";
import { useAuthStore } from "@/pages/auth/usecases/index";

export default {
  name: "EnderecoEscolha",
  components: {
    AddressForm,
    EnderecoRuralForm,
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  },
  data() {
    return {
      selectedAddressType: null,
    };
  },
  computed: {
    isEnderecoResidencialValido() {
      return this.authStore.getAuthorities.includes(
        AddressActions.FIND_ALL_ADDRESS
      );
    },
    isEnderecoRuralValido() {
      return this.authStore.getAuthorities.includes(
        AddressActions.ACAO_BUSCA_TODOS_ENDERECOS_RURAIS
      );
    },
  },
  watch: {
    isEnderecoResidencialValido(newValue) {
      this.updateSelectedAddressType();
    },
    isEnderecoRuralValido(newValue) {
      this.updateSelectedAddressType();
    },
  },
  mounted() {
    this.updateSelectedAddressType();
  },
  methods: {
    updateSelectedAddressType() {
      if (this.isEnderecoResidencialValido && !this.isEnderecoRuralValido) {
        this.selectedAddressType = "residencial";
      } else if (
        !this.isEnderecoResidencialValido &&
        this.isEnderecoRuralValido
      ) {
        this.selectedAddressType = "rural";
      } else if (
        this.isEnderecoResidencialValido &&
        this.isEnderecoRuralValido
      ) {
        this.selectedAddressType = "residencial";
      } else {
        this.selectedAddressType = null;
      }
    },
  },
};
</script>