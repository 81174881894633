export default class BillingRouteActions {
   static FIND_ALL_BILLING_ROUTE = "FIND_ALL_BILLING_ROUTE";
   static PROCURA_TODAS_ROTAS_DE_ENTREGA = "ProcuraTodasRotasDeEntrega";

    static values() {
        return [
            BillingRouteActions.FIND_ALL_BILLING_ROUTE,
            BillingRouteActions.PROCURA_TODAS_ROTAS_DE_ENTREGA,
        ];
    }
}

