export default class ComboActions {
    static FIND_COMBO_BY_ID = "FIND_COMBO_BY_ID";
    static FIND_ALL_COMBO = "FIND_ALL_COMBO";
    static SEARCH_COMBO = "SEARCH_COMBO";
    static CREATE_COMBO = "CREATE_COMBO";
    static UPDATE_COMBO = "UPDATE_COMBO";
    static DELETE_COMBO = "DELETE_COMBO";

    static values() {
        return [
            ComboActions.FIND_COMBO_BY_ID,
            ComboActions.FIND_ALL_COMBO,
            ComboActions.SEARCH_COMBO,
            ComboActions.CREATE_COMBO,
            ComboActions.UPDATE_COMBO,
            ComboActions.DELETE_COMBO,
        ];
    }
}

