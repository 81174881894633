import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { useItemStore } from "@/pages/item/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { comboRoutes } from "@/pages/combo/router/index";
import ComboActions from "@/pages/combo/enums/comboActions";

import * as createAction from "@/pages/combo/usecases/createAction.js";
import * as findPaginateAction from "@/pages/combo/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/combo/usecases/findAllAction.js";
import * as findByIdAction from "@/pages/combo/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/combo/usecases/updateByIdAction.js";
import * as deleteByIdAction from "@/pages/combo/usecases/deleteByIdAction.js";
import * as deleteAllByIdAction from "@/pages/combo/usecases/deleteAllByIdAction.js";
import * as searchAction from "@/pages/combo/usecases/searchAction.js";

export const useComboStore = defineStore("comboStore", {
  state: () => ({
    formData: {
      id: null,
      name: null,
      price: null,
      items: [],
    },
    list: [],
    listLoaded: false,
    isLoading: false,
    processing: false,
    isUpdate: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteById(field, fields) {
      await deleteByIdAction.deleteById(this, field, fields);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    async refresh() {
      await this.findPaginate({ page: this.getCurrentPage }, true);
    },

    addItems(items) {
      items.productId = items.product?.id;
      items.comboId = this.id;
      this.formData.items.push(items);
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(ComboActions.FIND_ALL_COMBO);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    cleanData() {
      this.formData.id = null;
      this.formData.name = null;
      this.formData.price = null;
      this.formData.items = [];
      useItemStore().cleanData();
      useItemStore().resetIds();
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(ComboActions.CREATE_COMBO),
        new ValidateActionHandler(),
        () => {
          this.isUpdate = false;
          this.cleanData();
          router.push(
            router.getPathFromRouteName(comboRoutes.comboRouteCreateEnum.name),
            null,
            () => {}
          );
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(ComboActions.FIND_ALL_COMBO),
        new ValidateEditHandler(),
        async (items) => {
          const id = items[0].id;
          this.isUpdate = true;
          router.push(
            router.getPathFromRouteName(comboRoutes.comboRouteSearchEnum.name) +
              "/" +
              id,
            null,
            () => {}
          );
        }
      );
    },

    actionDisable() {
      return new Action(
        ActionEnum.DISABLE.name,
        ActionEnum.DISABLE.icon,
        ActionEnum.DISABLE.action,
        this.hasPermission(ComboActions.DELETE_COMBO),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, this.getList)
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(ComboActions.SEARCH_COMBO),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              comboRoutes.comboRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(ComboActions.FIND_ALL_COMBO),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(ComboActions.FIND_ALL_COMBO),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(ComboActions.CREATE_COMBO),
        new ValidateActionHandler(),
        () => this.create()
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(ComboActions.UPDATE_COMBO),
        new ValidateActionHandler(),
        () => this.updateById()
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(ComboActions.SEARCH_COMBO),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDisable(),
        this.actionFilter(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
