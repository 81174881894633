export default class FormatPaymentEnum {
  static VAZIO = { cod: null, description: "" };
  static CASH_PAYMENT = {
    cod: "CASH_PAYMENT",
    description: "Pagamento a vista",
  };
  static DEFERRED = { cod: "DEFERRED", description: "Parcelado" };

  static values() {
    return [
      FormatPaymentEnum.VAZIO,
      FormatPaymentEnum.CASH_PAYMENT,
      FormatPaymentEnum.DEFERRED,
    ];
  }
}
