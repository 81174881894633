<template>
  <v-dialog v-model="dialog" width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        :value="formatDateTime(value)"
        :label="label"
        append-icon="mdi-calendar-clock"
        readonly
        outlined
      ></v-text-field>
    </template>
    <v-card class="t-datetime-picker white">
      <v-toolbar height="36" color="primary" dark flat>
        <v-tabs v-model="tab" grow height="36">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
          <v-btn depressed text class="rounded-0" @click="dialog = false">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker
            v-model="date"
            @input="tab = 'time'"
            full-width
            :min="minDate"
            :max="maxDate"
          ></v-date-picker>
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            :key="tab"
            v-model="time"
            format="24hr"
            full-width
            @click:minute="closeDialog"
          ></v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDateTime } from "@/utils/dateUtil";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      date: null,
      time: null,
      tab: "date",
    };
  },
  methods: {
    formatDateTime(value) {
      if (value === null) return value;
      return formatDateTime(value);
    },
    closeDialog() {
      this.dialog = false;
      if (!this.date) return;

      const dateObj = new Date(this.date);
      const dateISOString = dateObj.toISOString().split("T")[0];
      const timeString = this.time || "00:00";
      const dateTimeString = `${dateISOString}T${timeString}`;

      this.$emit("input", dateTimeString);
    },
  },
};
</script>

<style lang="scss" scoped>
.t-datetime-picker {
  .v-tabs-slider-wrapper {
    top: 0;
  }
  .v-picker__title {
    height: 90px;
  }
  .v-time-picker-title__time * {
    font-size: 60px;
    height: 60px;
  }
  .v-picker__body {
    height: 290px;
  }
  .v-tabs-items {
    height: 380px;
  }
}
</style>