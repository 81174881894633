import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import axiosInstance from "@/configs/axios";
import { routes } from "@/router/routes";

export const create = async (store) => {
  if (store.processing) return;

  store.processing = true;
  try {
    await axiosInstance.post(`${routes.payment}`, store.$state.formData);
    useSnackBarStore().mostrarSnackBar(
      messageSuccess(`Pagamento cadastrado com sucesso!`)
    );
    store.closeModal = true;
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    store.closeModal = false;
  } finally {
    store.processing = false;
  }
};