export default class PainelActions {
   static DADOS_PAINEL = "DADOS_PAINEL";

    static values() {
        return [
            PainelActions.DADOS_PAINEL,
        ];
    }
}

