<template>
  <v-container fluid>
    <v-card>
      <v-row class="mx-auto w-100 my-2">
        <v-col cols="12" md="8" sm="12">
          <v-text-field
            data-cy="contact-number-field"
            v-model="contactStore.formData.number"
            label="Número"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <v-select
            data-cy="type-contact-field"
            v-model="contactStore.formData.type"
            label="Tipo"
            :items="contactTypeOptions"
            item-text="description"
            item-value="cod"
            outlined
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="mx-1">
            <Button
              :name="'Cancelar'"
              :handleFunction="cancel"
              :permission="true"
            />
          </div>
          <div class="mx-1 mr-4">
            <Button
              :name="'Salvar'"
              :handleFunction="save"
              :permission="actionFinded.permission"
              :outlined="false"
              :color="'primary'"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { useContactStore } from "@/pages/contact/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import ActionEnum from "@/domain/action/ActionEnum";

import Button from "@/components/Button.vue";
import ContactTypeEnum from "@/pages/contact/enums/ContactTypeEnum";

export default {
  name: "ContactForm",
  components: {
    Button,
  },
  setup() {
    const contactStore = useContactStore();
    const dialogStore = useDialogStore();
    return {
      contactStore,
      dialogStore,
    };
  },
  data() {
    return {
      contactTypeOptions: ContactTypeEnum.values(),
      actionFinded: {},
      isUpdate: useDialogStore().isUpdate,
    };
  },
  created() {
    let action = ActionEnum.CREATE_SAVE.action;

    if (this.isUpdate) {
      const personId = useContactStore().formData.personId
      useContactStore().formData = useDialogStore().data;
      useContactStore().setPersonId(personId)
      action = ActionEnum.EDIT_SAVE.action;
    }
    
    const listeners = this.contactStore.getActionsListener();
    this.actionFinded = listeners.find((a) => a.action === action);
  },
  methods: {
    async save() {
      this.actionFinded.execute(useContactStore().formData);
      this.cancel();
    },
    async cancel() {
      useDialogStore().closeDialog();
    },
  },
};
</script>
