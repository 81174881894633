import { defineStore } from "pinia";

import ContactForm from "@/pages/contact/components/ContactForm.vue";
import { useUserStore } from "@/pages/user/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useCustomerStore } from "@/pages/customer/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import router from "@/router/index";
import ActionEnum from "@/domain/action/ActionEnum";
import ContactActions from "@/pages/contact/enums/ContactActions";
import NextIdGeneration from "@/domain/NextIdGeneration";

import * as createAction from "@/pages/contact/usecases/createAction";
import * as findByIdAction from "@/pages/contact/usecases/findByIdAction";
import * as updateByIdAction from "@/pages/contact/usecases/updateByIdAction";
import * as deleteAllByIdAction from "@/pages/contact/usecases/deleteAllByIdAction";
import * as openWhatsAppAction from "@/pages/contact/usecases/openWhatsAppAction";

export const useContactStore = defineStore("contactStore", {
  state: () => ({
    formData: {
      id: null,
      number: null,
      type: null,
      person: {},
      personId: null,
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findById(id) {
      return await findByIdAction.findById(id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    openWhatsapp(number) {
      openWhatsAppAction.openWhatsapp(number);
    },

    setPersonId(id) {
      this.formData.personId = id;
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    cleanData() {
      this.formData.id = null;
      this.formData.number = null;
      this.formData.type = null;
      this.formData.person = null;
    },

    resetIds() {
      this.formData.personId = null;
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(ContactActions.CREATE_CONTACT),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          useDialogStore().openDialogWithData({
            title: "Cria contato",
            childComponent: ContactForm,
            isUpdate: false,
          });
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(ContactActions.FIND_ALL_CONTACT),
        new ValidateEditHandler(),
        (items) => {
          useDialogStore().openDialogWithData({
            title: "Edita contato",
            childComponent: ContactForm,
            isUpdate: true,
            data: items[0],
          });
        }
      );
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        this.hasPermission(ContactActions.DELETE_CONTACT),
        new ValidateDeleteHandler(),
        (items) => {
          const route = router.getRouteFromRouteName(
            router.history.current.name
          );
          if (route.id === "customer") {
            if (this.formData.personId) {
              this.deleteAllById(items, useCustomerStore().formData.contacts);
            } else {
              items.forEach((item) => {
                const findObject = useCustomerStore().formData.contacts.find(
                  (element) => element.id === item.id
                );
                useCustomerStore().formData.contacts.splice(
                  useCustomerStore().formData.contacts.indexOf(findObject),
                  1
                );
              });
            }
          } else {
            if (this.formData.personId) {
              this.deleteAllById(items, useUserStore().formData.contacts);
            } else {
              items.forEach((item) => {
                const findObject = useUserStore().formData.contacts.find(
                  (element) => element.id === item.id
                );
                useUserStore().formData.contacts.splice(
                  useUserStore().formData.contacts.indexOf(findObject),
                  1
                );
              });
            }
          }
        }
      );
    },

    actionOpenWhatsApp() {
      return new Action(
        "Abrir contato no whatsApp",
        "mdi-whatsapp",
        "openWhatsApp",
        true,
        new ValidateEditHandler(),
        (items) => {
          this.openWhatsapp(items[0].number);
        }
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(ContactActions.FIND_ALL_CONTACT),
        new ValidateActionHandler(),
        () => this.findPaginate({ page: this.getCurrentPage })
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(ContactActions.CREATE_CONTACT),
        new ValidateActionHandler(),
        (item) => {
          if (this.formData.personId) {
            this.create();
          } else {
            let contacts = [];
            const route = router.getRouteFromRouteName(
              router.history.current.name
            );
            if (route.id === "customer") {
              contacts = useCustomerStore().formData.contacts;
              item.id = new NextIdGeneration(contacts).nextId();
              useCustomerStore().addContact(JSON.parse(JSON.stringify(item)));
            } else {
              contacts = useUserStore().formData.contacts;
              item.id = new NextIdGeneration(contacts).nextId();
              useUserStore().addContact(JSON.parse(JSON.stringify(item)));
            }
          }
        }
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(ContactActions.UPDATE_CONTACT),
        new ValidateActionHandler(),
        (item) => {
          let findObject = {};
          const route = router.getRouteFromRouteName(
            router.history.current.name
          );
          if (route.id === "customer") {
            findObject = useCustomerStore().formData.contacts.find(
              (element) => element.id === item.id
            );
          } else {
            findObject = useUserStore().formData.contacts.find(
              (element) => element.id === item.id
            );
          }

          if (!findObject) {
            useSnackBarStore().mostrarSnackBar(
              messageError(
                error(
                  "Não foi possível encontrar esse registro, contate o suporte."
                )
              )
            );
            return;
          }

          if (this.formData.personId || this.formData.person) this.updateById();
        }
      );
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDelete(),
        this.actionOpenWhatsApp(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionCreateSave(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
