import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import axiosInstance from "@/configs/axios";
import { routes } from "@/router/routes";

export const updateById = async (store) => {
  if (store.processing) return;

  store.processing = true;
  const formData = store.$state.formData
  try {
    await axiosInstance.put(`${routes.installment}/${formData.id}`, formData);
    useSnackBarStore().mostrarSnackBar(
      messageSuccess(`Item de pedido atualizada com sucesso!`)
    );
    store.closeModal = true;
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    store.closeModal = false;
  } finally {
    store.processing = false;
  }
};