import { defineStore } from "pinia";

import PaymentForm from "@/pages/payment/components/PaymentForm.vue";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useOrderStore } from "@/pages/order/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import PaymentActions from "@/pages/payment/enums/PaymentActions";
import { vue } from "@/main";

import * as createAction from "@/pages/payment/usecases/createAction.js";
import * as findByIdAction from "@/pages/payment/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/payment/usecases/updateByIdAction.js";
import * as deleteAllByIdAction from "@/pages/payment/usecases/deleteAllByIdAction.js";
import * as submitOrderReceiptMobileAction from "@/pages/payment/usecases/submitOrderReceiptMobileAction.js";
import * as submitOrderReceiptWebAction from "@/pages/payment/usecases/submitOrderReceiptWebAction.js";

export const usePaymentStore = defineStore("paymentStore", {
  state: () => ({
    formData: {
      id: null,
      type: null,
      paymentValue: null,
      paymentDate: null,
      ordersId: null,
      user: {},
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    processing: false,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(object, arrayObjects) {
      await deleteAllByIdAction.deleteAllById(this, object, arrayObjects);
    },

    async orderReceiptWeb(payment) {
      await submitOrderReceiptWebAction.submitOrderReceiptWeb(payment.id);
    },

    async orderReceiptMobile(payment) {
      await submitOrderReceiptMobileAction.submitOrderReceiptMobile(payment.id);
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(PaymentActions.CREATE_PAYMENT);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    cleanData() {
      this.formData.id = null;
      this.formData.type = null;
      this.formData.paymentValue = null;
      this.formData.paymentDate = null;
      this.formData.user = {};
    },

    resetIds() {
      this.formData.ordersId = null;
    },

    setOrderId(id) {
      this.formData.ordersId = id;
    },

    printerOrderReceipt(payment) {
      if (vue.$vuetify.breakpoint.xs) {
        this.orderReceiptMobile(payment);
      } else {
        this.orderReceiptWeb(payment);
      }
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(PaymentActions.CREATE_PAYMENT),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          useDialogStore().openDialogWithData({
            title: "Cria pagamento",
            childComponent: PaymentForm,
            isUpdate: false,
          });
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(PaymentActions.UPDATE_PAYMENT),
        new ValidateEditHandler(),
        async (items) => {
          useDialogStore().openDialogWithData({
            title: "Edita pagamento",
            childComponent: PaymentForm,
            isUpdate: true,
            data: items[0],
          });
        }
      );
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        this.hasPermission(PaymentActions.DELETE_PAYMENT),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, useOrderStore().formData.payments)
      );
    },

    actionSubmitPdf() {
      if (this.formData.id === null) return {};

      const action = new Action(
        ActionEnum.PRINTER.name,
        ActionEnum.PRINTER.icon,
        ActionEnum.PRINTER.action,
        this.hasPermission(
          PaymentActions.SUBMIT_ORDER_RECEIPT_MOBILE ||
            PaymentActions.SUBMIT_ORDER_RECEIPT_WEB
        ),
        new ValidateActionHandler(),
        (objects) => {
          if (Array.isArray(objects)) {
            objects.forEach((element) => {
              this.printerOrderReceipt(element);
            });
          } else {
            this.printerOrderReceipt(objects);
          }
        }
      );
      action.setVibisibleInCardList();
      return action;
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(PaymentActions.CREATE_PAYMENT),
        new ValidateActionHandler(),
        (item) => {
          if (this.formData.ordersId) this.create();
        }
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(PaymentActions.UPDATE_PAYMENT),
        new ValidateActionHandler(),
        (item) => {
          if (this.formData.ordersId) this.updateById();
        }
      );
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDelete(),
        this.actionSubmitPdf(),
      ];
    },

    getMenuFormActions() {
      return [this.actionSubmitPdf()];
    },

    getActionsListener() {
      return [
        ...this.getMenuActions(),
        ...this.getMenuFormActions(),
        this.actionCreateSave(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
