export default class ContactTypeEnum {
  static VAZIO = { cod: null, description: "" };
  static RESIDENTIAL = { cod: "RESIDENTIAL", description: "Residencial" };
  static BUSINESS = { cod: "BUSINESS", description: "Comercial" };
  static CELLPHONE = { cod: "CELLPHONE", description: "Celular" };

  static values() {
    return [
      ContactTypeEnum.VAZIO,
      ContactTypeEnum.RESIDENTIAL,
      ContactTypeEnum.BUSINESS,
      ContactTypeEnum.CELLPHONE,
    ];
  }
}
