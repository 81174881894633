import OrderActions from '@/pages/order/enums/OrderActions'

export const orderRouteCreateEnum =
{
  id: 'order',
  path: "/pedido/novo/:customerId",
  name: "order-novo",
  description: "Novo pedido",
  order: -1,
  component: () => import("@/pages/order/components/OrderForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ OrderActions.CREATE_ORDER ]
  }
}
