import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { roleRoutes } from "@/pages/role/router/index";
import RoleActions from "@/pages/role/enums/RoleActions";

import * as createAction from "@/pages/role/usecases/createAction.js";
import * as findPaginateAction from "@/pages/role/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/role/usecases/findAllAction.js";
import * as findByIdAction from "@/pages/role/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/role/usecases/updateByIdAction.js";
import * as deleteAllByIdAction from "@/pages/role/usecases/deleteAllByIdAction.js";

export const useRoleStore = defineStore("roleStore", {
  state: () => ({
    formData: {
      id: null,
      name: null,
      description: null,
      permissions: [],
      users: [],
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findPaginate(pagination) {
      return await findPaginateAction.findPaginate(this, pagination);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(RoleActions.FIND_ALL_ROLE);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    cleanData() {
      this.formData.id = null;
      this.formData.name = null;
      this.formData.description = null;
      this.formData.permissions = [];
      this.formData.users = [];
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(RoleActions.CREATE_ROLE),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          router.push(
            router.getPathFromRouteName(roleRoutes.roleRouteCreateEnum.name),
            null,
            () => {}
          );
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(RoleActions.FIND_ALL_ROLE),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(roleRoutes.roleRouteSearchEnum.name) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        this.hasPermission(RoleActions.DELETE_ROLE),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, this.getList)
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(RoleActions.FIND_ALL_ROLE),
        new ValidateActionHandler(),
        () => this.findPaginate({ page: this.getCurrentPage })
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(RoleActions.FIND_ALL_ROLE),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(RoleActions.CREATE_ROLE),
        new ValidateActionHandler(),
        (items) => this.create()
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(RoleActions.UPDATE_ROLE),
        new ValidateActionHandler(),
        (items) => this.updateById()
      );
    },

    getMenuActions() {
      return [this.actionCreate(), this.actionEdit(), this.actionDelete()];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
