import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";

export const findPaginate = async (store, pagination) => {
    store.isLoading = true;
      try {
        const response = await axiosInstance.get(routes.role, {
          params: {
            page: pagination.page - 1,
            size: store.$state.itemsPerPage,
          },
        });
        const data = response.data;
        store.totalPages = data.totalPages;
        store.totalElements = data.totalElements;
        store.currentPage = pagination.page;
        store.list = data.content;
        store.listLoaded = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      } finally {
        store.isLoading = false;
      }
};