import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";

export async function getGeolocation() {
  if (!("geolocation" in navigator)) {
    throw new Error("Geolocalização não suportada pelo navegador.");
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0
  };

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });

    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
  } catch (e) {
    let errorMessage = "Erro desconhecido ao obter a localização.";

    if (e.code === e.PERMISSION_DENIED) {
      errorMessage = "Permissão de geolocalização negada.";
    } else if (e.code === e.POSITION_UNAVAILABLE) {
      errorMessage = "Localização indisponível.";
    } else if (e.code === e.TIMEOUT) {
      errorMessage = "Timeout ao obter a localização.";
    }

    useSnackBarStore().mostrarSnackBar(messageError(error(errorMessage)));
  }
}

export async function openMapsAddress(adr) {
  try {
    const address =
      adr.streetAddress.trim() + ", " + adr.number.trim() + ", Campo Grande - MS";
    const formatAddress = address.replace(/\s/g, "+");
    const url = `https://www.google.com/maps/dir/?api=1&destination=${formatAddress}&travelmode=motorcycle`;
    window.open(url);
  } catch (e) {
    let errorMessage = "Erro desconhecido ao obter a google maps.";
    useSnackBarStore().mostrarSnackBar(messageError(error(errorMessage)));
  }
}

export async function openMapsItem(item) {
  try {
    const address = item.address.trim() + " Campo Grande - MS";
    const formatAddress = address.replace(/\s/g, "+");
    const url = `https://www.google.com/maps/dir/?api=1&destination=${formatAddress}&travelmode=motorcycle`;
    window.open(url);
  } catch (e) {
    let errorMessage = "Erro desconhecido ao obter a google maps.";
    useSnackBarStore().mostrarSnackBar(messageError(error(errorMessage)));
  }
}
