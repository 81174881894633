<template>
  <v-container fluid>
    <CardList
      :name="'Endereços'"
      :headers="headers"
      :items="listObjects"
      :loading="addressStore.getIsLoading"
      :menu="getMenu()"
      :refresh="false"
      :itemsPerPage="addressStore.getItemsPerPage"
      :totalElements="addressStore.getTotalElements"
      @action="(items, aciton) => handleAction(items, aciton)"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
import { useAddressStore } from "@/pages/address/usecases/index";
import { openMapsAddress } from "@/utils/routesMap";

export default {
  name: "AddressPage",
  components: {
    CardList,
  },
  props: {
    listObjects: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  setup() {
    const addressStore = useAddressStore();
    return {
      addressStore,
      openMapsAddress,
    };
  },
  data() {
    return {
      isUpdate: false,
      headers: [
        { text: "Rua", align: "start", value: "streetAddress" },
        { text: "Propriedade", value: "propriedade" },
        { text: "Observação", value: "observacao" },
        { text: "Numero", value: "number" },
        { text: "Cep", value: "zip" },
        { text: "Bairro", value: "district" },
        { text: "Complemento", value: "addressInformation" },
        {
          text: "Região",
          value: "region.name",
          layout: { chip: { permissao: true } },
        },
        {
          text: "Tippo",
          value: "tipoEndereco",
          layout: { chip: { permissao: true } },
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    handleAction(items, action) {
      const listener = this.addressStore.getActionsListener();
      const actionFinded = listener.find((a) => a.action === action);
      actionFinded.execute(items);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.addressStore.getMenuActions(),
      };
    },
  },
};
</script>
