import RoleActions from '@/pages/role/enums/RoleActions'

export const roleRouteCreateEnum =
{
  id: 'role',
  path: "/papel/novo",
  name: "role-novo",
  description: "Novo papel",
  order: -1,
  component: () => import("@/pages/role/components/RoleForm.vue"),
  meta: {
    requiresAuth: true,
    requiresroles: [ RoleActions.CREATE_ROLE ]
  }
}
