import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import router from "@/router/index";
import axiosInstance from "@/configs/axios";

export const updateById = async (store, fields) => {
  if (store.processing) return;

  store.processing = true;
  store.isLoading = true;
  try {
    await axiosInstance.put(`${routes.product}/${fields.id}`, fields);
    store.refresh();
    useSnackBarStore().mostrarSnackBar(
      messageSuccess(`Produto atualizado com sucesso!`)
    );
    router.back()
    store.closeModal = true;
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    store.closeModal = false;
  } finally {
    store.isLoading = false;
    store.processing = false;
  }
};