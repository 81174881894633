<template>
  <v-container fluid>
    <v-card>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <Menu
          :name="getMenu().name"
          :permission="getMenu().permission"
          :icon="getMenu().icon"
          :color="getMenu().color"
          :outlined="getMenu().outlined"
          :items="getMenu().items"
          @action="(action) => clickMenuAction(action)"
        />
      </v-toolbar>
      <v-row class="mx-auto w-100 my-2">
        <v-col cols="12" md="12" sm="12">
          <v-autocomplete
            data-cy="orders-delivery-address-field"
            v-model="orderStore.formData.customer"
            :item-text="formatCustomer"
            label="Cliente"
            :items="[orderStore.formData.customer]"
            outlined
            return-object
            hide-no-data
            single-line
            append-icon="mdi-account"
            disabled
          />
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-autocomplete
            data-cy="orders-delivery-address-field"
            v-model="orderStore.formData.deliveryAddress"
            :item-text="formatDeliveryAddress"
            label="Endereço de entrega"
            :items="orderStore.formData.deliveryAddresses"
            outlined
            return-object
            hide-no-data
            single-line
            append-icon="mdi-truck-delivery"
          />
        </v-col>
        <v-col cols="12" md="2" sm="12">
          <!-- // TODO Foi deixado o rderStore.formData.discount !== null && orderStore.formData.id !== null 
          por conta do modulo de desconto ser incapaz de processar novos descontos -->
          <v-text-field
            data-cy="discount-orders-field"
            v-model.number="orderStore.formData.discount"
            label="Desconto"
            type="number"
            :disabled="isDiscountEditable"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2" sm="12">
          <v-select
            data-cy="orders-form-payment-field"
            v-model="orderStore.formData.formPayment"
            label="Forma de pagamento"
            :items="formPaymentEnumOptions"
            item-text="description"
            item-value="cod"
            outlined
            hide-no-data
            append-icon="mdi-wallet"
            :disabled="isUpdate"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <DateField
            v-model="orderStore.formData.dueDate"
            label="Selecione a data de vencimento"
            :minDate="minDate"
            :disabled="isUpdate"
          ></DateField>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <DateField
            v-model="orderStore.formData.instante"
            label="Selecione a data de criação"
          ></DateField>
        </v-col>
        <v-col cols="12" md="2" sm="12">
          <v-select
            v-model="orderStore.formData.status"
            label="Status"
            :items="statusPedidoEnumEnumOptions"
            item-text="description"
            item-value="cod"
            :disabled="true"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-select
            v-if="
              orderStore.formData.formPayment &&
              orderStore.formData.formPayment !== 'CASH_PAYMENT'
            "
            v-model="orderStore.formData.quantityInstallments"
            data-cy="orders-quantity-installment-field"
            label="Quantidade de parcelas"
            :items="defferredOptions"
            outlined
            hide-no-data
            append-icon="mdi-numeric-1-box"
            :disabled="isUpdate"
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="12" sm="12">
          <ItemPage :objectsList="orderStore.formData.items" />
        </v-col>

        <v-col cols="12" md="12" sm="12" v-if="isUpdate">
          <InstallmentsPage :objectsList="orderStore.formData.installments" />
        </v-col>

        <v-col cols="12" md="12" sm="12" v-if="isUpdate">
          <PaymentPage :objectsList="orderStore.formData.payments" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="mx-1">
            <Button
              :name="'Cancelar'"
              :handleFunction="cancel"
              :permission="true"
            />
          </div>
          <div class="mx-1 mr-4">
            <Button
              :name="'Salvar'"
              :handleFunction="save"
              :permission="actionFinded.permission"
              :outlined="false"
              :color="'primary'"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ItemPage from "@/pages/item/ItemPage.vue";
import PaymentPage from "@/pages/payment/PaymentPage.vue";
import InstallmentsPage from "@/pages/installment/InstallmentsPage.vue";
import Button from "@/components/Button.vue";
import DateField from "@/components/DateField.vue";
import ActionEnum from "@/domain/action/ActionEnum";
import FormPaymentEnum from "@/pages/payment/enums/FormPaymentEnum";
import Menu from "@/components/menu/Menu.vue";

import { useOrderStore } from "@/pages/order/usecases/index";
import { useCustomerStore } from "@/pages/customer/usecases/index";
import { useInstallmentStore } from "@/pages/installment/usecases/index";
import { usePaymentStore } from "@/pages/payment/usecases/index";
import { useItemStore } from "@/pages/item/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { todayDateField } from "@/utils/dateUtil";
import StatusPedidoEnum from "../enums/StatusPedidoEnum";

export default {
  name: "OrderForm",
  components: {
    ItemPage,
    PaymentPage,
    InstallmentsPage,
    Button,
    DateField,
    Menu,
  },
  setup() {
    const orderStore = useOrderStore();
    const customerStore = useCustomerStore();
    const installmentStore = useInstallmentStore();
    const paymentStore = usePaymentStore();
    const itemStore = useItemStore();
    const dialogStore = useDialogStore();
    return {
      orderStore,
      customerStore,
      installmentStore,
      paymentStore,
      itemStore,
      dialogStore,
    };
  },
  data() {
    return {
      isUpdate: false,
      actionFinded: {},
      listeners: [],
      minDate: todayDateField,
      isDiscountEditable: false,
      formPaymentEnumOptions: FormPaymentEnum.values(),
      statusPedidoEnumEnumOptions: StatusPedidoEnum.values(),
      defferredOptions: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
  },
  async created() {
    let id = this.$route.params.id;
    if (this.isDialogFormAtivo()) {
      id = this.dialogStore.getDialogAtivo?.data?.id;
    }

    if (id) {
      this.loadData(id);
      this.isUpdate = true;
    }

    const action = this.isUpdate
      ? ActionEnum.EDIT_SAVE.action
      : ActionEnum.CREATE_SAVE.action;
    this.listeners = this.orderStore.getActionsListener();
    this.actionFinded = this.listeners.find((a) => a.action === action);

    const customerId = this.$route.params.customerId;
    if (customerId) {
      this.loadDataCustomer(customerId);
    }
  },
  methods: {
    isDialogFormAtivo() {
      let dialogAtivo = this.dialogStore.getDialogAtivo;
      return dialogAtivo?.childComponent?.name === "OrderForm";
    },
    async loadData(id) {
      const object = await this.orderStore.findById(id);
      if (!object) return;

      const data = JSON.parse(JSON.stringify(this.orderStore.getOrder(object)));
      useOrderStore().formData = data;
      this.isDiscountEditable = data.id === null || data.discount > 0;
      this.setOrderIdToStores();
    },
    setOrderIdToStores() {
      const orderId = useOrderStore().formData.id;
      useInstallmentStore().setOrderId(orderId);
      usePaymentStore().setOrderId(orderId);
      useItemStore().setOrderId(orderId);
    },
    async loadDataCustomer(customerId) {
      useOrderStore().formData.customer = this.customerStore.getCustomer(
        await this.customerStore.findById(customerId)
      );
      useOrderStore().formData.customerId =
        useOrderStore().formData.customer?.id;
      useOrderStore().formData.deliveryAddresses =
        useOrderStore().formData.customer.address;
    },
    async save() {
      useOrderStore().formData.deliveryAddressId =
        useOrderStore().formData.deliveryAddress?.id;
      this.actionFinded.execute(this.fields);
    },
    cancel() {
      this.isDialogFormAtivo()
        ? this.dialogStore.closeDialog()
        : this.$router.back();
    },
    formatDeliveryAddress(address) {
      if (!address) return "";

      if (address.tipoEndereco === "RURAL") {
        return `Propriedade: ${address.propriedade}, Observação: ${address.observacao}`;
      }

      const addressParts = [
        `Rua: ${address.streetAddress}`,
        `Numero: ${address.number}`,
        `Bairro: ${address.district}`,
        `CEP: ${address.zip}`,
      ];

      if (address.addressInformation) {
        addressParts.push(`Complemento: ${address.addressInformation}`);
      }

      return addressParts.join(", ");
    },
    formatCustomer(customer) {
      if (!customer) return "";
      if (customer.name) return customer.name;
      if (customer.person?.name) return customer.person.name;
    },
    async clickMenuAction(action) {
      const actionFinded = this.listeners.find((a) => a.action === action);
      if (!actionFinded) return;
      actionFinded.execute(useOrderStore().formData);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.orderStore.getMenuFormActions(),
      };
    },
  },
};
</script>
