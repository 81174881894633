export default class WorkOrderActions {
     static FIND_ALL_WORK_ORDER = "FIND_ALL_WORK_ORDER";
     static FIND_WORK_ORDER_BY_ID = "FIND_WORK_ORDER_BY_ID";
     static SEARCH_WORK_ORDER = "SEARCH_WORK_ORDER";
     static CREATE_WORK_ORDER = "CREATE_WORK_ORDER";
     static UPDATE_WORK_ORDER = "UPDATE_WORK_ORDER";
     static UPDATE_WORK_ORDER_ANOTHER_USER = "UPDATE_WORK_ORDER_ANOTHER_USER";

    static values() {
        return [
            WorkOrderActions.FIND_ALL_WORK_ORDER,
            WorkOrderActions.FIND_WORK_ORDER_BY_ID,
            WorkOrderActions.SEARCH_WORK_ORDER,
            WorkOrderActions.CREATE_WORK_ORDER,
            WorkOrderActions.UPDATE_WORK_ORDER,
            WorkOrderActions.UPDATE_WORK_ORDER_ANOTHER_USER,
        ];
    }
}

