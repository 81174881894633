export default class PaymentStatusEnum {
  static VAZIO = { cod: null, description: '' };
  static PENDING = { cod: "PENDING", description: "Pagamento pendente" };
  static TOTAL = { cod: "TOTAL", description: "Pagamento total" };
  static CANCELED = { cod: "CANCELED", description: "Pagamento cancelado" };
  static PARTIAL = { cod: "PARTIAL", description: "Pagamento parcial" };
  static GREATER = { cod: "GREATER", description: "Pagamento a maior" };

  static values() {
    return [
      PaymentStatusEnum.VAZIO,
      PaymentStatusEnum.PENDING,
      PaymentStatusEnum.TOTAL,
      PaymentStatusEnum.CANCELED,
      PaymentStatusEnum.PARTIAL,
      PaymentStatusEnum.GREATER,
    ];
  }

  static formattedPaymentStatus(paymentStatus) {
    switch (paymentStatus) {
      case PaymentStatusEnum.VAZIO.cod:
        return PaymentStatusEnum.VAZIO.description;
      case PaymentStatusEnum.PENDING.cod:
        return PaymentStatusEnum.PENDING.description;
      case PaymentStatusEnum.TOTAL.cod:
        return PaymentStatusEnum.TOTAL.description;
      case PaymentStatusEnum.CANCELED.cod:
        return PaymentStatusEnum.CANCELED.description;
      case PaymentStatusEnum.PARTIAL.cod:
        return PaymentStatusEnum.PARTIAL.description;
      case PaymentStatusEnum.GREATER.cod:
        return PaymentStatusEnum.GREATER.description;
      default:
        return paymentStatus;
    }
  }
}
