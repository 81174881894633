import ContactActions from '@/pages/contact/enums/ContactActions'

export const contactRouteCreateEnum = {
    id: 'contact',
    path: "/contatos/novo",
    name: "contacts-novo",
    description: 'Novo contato',
    order: -1,
    component: () => import("@/pages/contact/components/ContactForm.vue"),
    meta: {
        requiresAuth: true,
        requiresPermissions: [ContactActions.CREATE_CONTACT]
    }
}