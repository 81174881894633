const today = new Date();
const mesAtual = today.getMonth() + 1
const monthDate = mesAtual < 10 ? `0${mesAtual}` : `${mesAtual}`;
const dayDate = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;

export const todayDateField = `${today.getFullYear()}-${monthDate}-${dayDate}`;

const yesterday = new Date(Date.now() - 86400000)
const mesAtualOntem = yesterday.getMonth() + 1
const monthYesterday = mesAtualOntem < 10 ? `0${mesAtualOntem}` : `${mesAtualOntem}`;
const dayYesterday = yesterday.getDate() < 10 ? `0${yesterday.getDate()}` : `${yesterday.getDate()}`;

export const yesterdayDateField = `${yesterday.getFullYear()}-${monthYesterday}-${dayYesterday}`;


export const formatDateTime = (value) => {
    const date = new Date(value);
    const mesAtual = date.getMonth() + 1
    const monthDate = mesAtual < 10 ? `0${mesAtual}` : `${mesAtual}`;
    const dayDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${dayDate}/${monthDate}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
};


export const formatDate = (value) => {
    const parts = value.split('-');
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    const mesAtual = date.getMonth() + 1
    const monthDate = mesAtual < 10 ? `0${mesAtual}` : `${mesAtual}`;
    const dayDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${dayDate}/${monthDate}/${date.getFullYear()}`
};