import stockActions from '@/pages/stock/enums/stockActions'

export const stockRouteSearchParametersEnum =
{
  id: 'stock',
  path: "/estoque/pesquisa",
  name: "stock-search-parameters",
  description: "Pesquisa estoque com parâmetros",
  order: -1,
  component: () => import("@/pages/stock/components/StockFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ stockActions.SEARCH_STOCK ]
  }
}

