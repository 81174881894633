import ParameterActions from '@/pages/parameter/enums/ParameterActions'

export const parameterRouteListEnum =
{
  id: 'parameter',
  path: "/parametros",
  name: "parameter",
  description: "Parâmetros",
  order: 1,
  component: () => import("@/pages/parameter/ParameterPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ParameterActions.FIND_PARAMETER_ALL ]
  }
}
