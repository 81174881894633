import { defineStore } from "pinia";
import axiosInstance from "@/configs/axios";
import { routes } from "@/router/routes";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";

export const useCityStore = defineStore("cityStore", {
  state: () => ({
    name: "",
    state: {},
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
  }),
  actions: {
    async create(fields) {
      if (this.processing) return;

      this.processing = true;
      this.setStore(fields);
      try {
        await axiosInstance.post(routes.city, this.$state);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Cidade cadastrada com sucesso!`)
        );
        await this.findAll();
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async findAll() {
      try {
        const response = await axiosInstance.get(routes.city);
        this.list = response.data.content;
        this.listLoaded = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      }
    },

    async findById(id) {
      try {
        const response = await axiosInstance.get(`${routes.city}/${id}`);
        return response.data.content;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      }
    },

    async updateById(id, fields) {
      if (this.processing) return;

      this.processing = true;
      try {
        await axiosInstance.put(`${routes.city}/${id}`, fields);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Cidade atualizada com sucesso!`)
        );
        await this.findAll();
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async deleteById(object, arrayObjects) {
      if (this.processing) return;

      this.processing = true;
      try {
        await axiosInstance.delete(`${routes.city}/${object.id}`);
        arrayObjects.splice(arrayObjects.indexOf(object), 1);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Cidade desabilitada com sucesso!`)
        );
        await this.findAll();
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async getCity(name) {
      let city = {};
      try {
        city = await axiosInstance.get(`${routes.city}/search`, {
          params: {
            name: name,
          },
        });
      } catch (error) {
        useSnackBarStore().mostrarSnackBar(
          messageError(error("Cidade não cadastrada"))
        );
      }
      return city;
    },

    setStore(fields) {
      this.name = fields.name;
      this.state = fields.state;
    },
  },
  getters: {
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findAll();
      }
      return this.$state.list;
    },
  },
});
