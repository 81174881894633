import ReportActions from '@/pages/report/enums/ReportActions'

export const reportRouteSearchEnum =
{
  id: 'report',
  path: "/relatorio/pesquisa/:id",
  name: "report-pesquisa",
  description: "Pesquisa relatório",
  order: -1,
  component: () => import("@/pages/report/components/ReportForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ReportActions.FIND_REPORT_BY_ID ]
  }
}

