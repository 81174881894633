export default class ContactActions {
    static FIND_ALL_CONTACT = "FIND_ALL_CONTACT";
    static FIND_CONTACT_BY_ID = "FIND_CONTACT_BY_ID";
    static CREATE_CONTACT = "CREATE_CONTACT";
    static UPDATE_CONTACT = "UPDATE_CONTACT";
    static DELETE_CONTACT = "DELETE_CONTACT";

    static values() {
        return [
            ContactActions.FIND_ALL_CONTACT,
            ContactActions.FIND_CONTACT_BY_ID,
            ContactActions.CREATE_CONTACT,
            ContactActions.UPDATE_CONTACT,
            ContactActions.DELETE_CONTACT,
        ];
    }
}

