import { defineStore } from 'pinia';
import router from '@/router/index'

export const useBreadcrumbStore = defineStore("breadcrumbStore", {
  id: 'breadcrumb',
  state: () => ({
    breadcrumbTrail: [],
  }),
  actions: {
    getBreadcrumbTrail() {
      this.breadcrumbTrail = this.buildBreadcrumb()
      return this.breadcrumbTrail;
    },
    buildBreadcrumb() {
      return this.getRoutesOrderFromRouteNameWithTheSameId()
    },
    getRoutesOrderFromRouteNameWithTheSameId() {
      const currentRoute = router.history.current;
      const routes = router.options.routes;
      const currentRegionRoute = routes.find(route => route.name === currentRoute.name);
      if (!currentRegionRoute) return [];

      const filteredRoutes = routes.filter(route => route.id === currentRegionRoute.id && route.order !== -1);
      const sortedRoutes = filteredRoutes.sort((a, b) => a.order - b.order);

      const breadcrumbTrail = sortedRoutes.map(route => ({
        text: route.description,
        to: route.path,
        disabled: false
      }));

      breadcrumbTrail.push({
        text: currentRegionRoute.description,
        to: currentRoute.path,
        disabled: true
      });

      return breadcrumbTrail;
    }
  },
});