import Action from '@/domain/action/Action'

export class ValidateActionHandler {
    getAction() {
        return Action;
    }

    validate(items)  {
        // 'Should sobrescribe in the child class validation'; 
    }

}