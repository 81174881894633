import { defineStore } from "pinia";

export const useSnackBarStore = defineStore("snackBarStore", {
    state: () => ({
        content: "",
        color: "",
        snack: false
    }),
    actions: {
        mostrarSnackBar(payload) {
            this.content = payload.content
            this.color = payload.color
            this.snack = true
        },
        messageSuccess(message) {
            return {
                content: message,
                color: "green",
            };
        },

        messageError(message) {
            return {
                content: message,
                color: "error",
            };
        },

        errorFormated(errors) {
            const messages = [];
            errors.forEach(error => {
                messages.push(error.message);
            });
            return messages.join('\n');
        },

        error(error) {
            const message =
                error.response === undefined
                    ? error
                    : error.response.data.errors !== undefined
                        ? errorFormated(error.response.data.errors)
                        : error.response.data.message;

            return message || "Ocorreu um erro inesperado";
        },

        async errorMedia(error) {
            const text = await error.response.data.text()
            const json = JSON.parse(text)
            let message = json.message;

            return message || "Ocorreu um erro inesperado";
        },

    },
});
