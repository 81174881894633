import RegionActions from '@/pages/region/enums/RegionActions'

export const regionRouteCreateEnum =
{
  id: 'region',
  path: "/regiao/novo",
  name: "region-novo",
  description: "Nova região",
  order: -1,
  component: () => import("@/pages/region/components/RegionForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ RegionActions.CREATE_REGION ]
  }
}
