export default class ProductActions {
     static FIND_PRODUCT_BY_ID = "FIND_PRODUCT_BY_ID";
     static FIND_ALL_PRODUCT = "FIND_ALL_PRODUCT";
     static SEARCH_PRODUCT = "SEARCH_PRODUCT";
     static CREATE_PRODUCT = "CREATE_PRODUCT";
     static UPDATE_PRODUCT = "UPDATE_PRODUCT";
     static DELETE_PRODUCT = "DELETE_PRODUCT";

    static values() {
        return [
            ProductActions.FIND_PRODUCT_BY_ID,
            ProductActions.FIND_ALL_PRODUCT,
            ProductActions.SEARCH_PRODUCT,
            ProductActions.CREATE_PRODUCT,
            ProductActions.UPDATE_PRODUCT,
            ProductActions.DELETE_PRODUCT,
        ];
    }
}

