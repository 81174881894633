import { customerRouteCreateEnum } from '@/pages/customer/router/customerRouteCreate'
import { customerRouteSearchEnum } from '@/pages/customer/router/customerRouteSearch'
import { customerRouteListEnum } from '@/pages/customer/router/customerRouteList'
import { customerRouteSearchParametersEnum } from '@/pages/customer/router/customerRouteSearchParameters'


export const customerRoutes = {
    customerRouteCreateEnum,
    customerRouteSearchEnum,
    customerRouteListEnum,
    customerRouteSearchParametersEnum,
}
