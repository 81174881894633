import { defineStore } from "pinia";

import AddressForm from "@/pages/address/components/AddressForm";
import EnderecoRuralForm from "@/pages/address/components/EnderecoRuralForm";
import EnderecoEscolha from "@/pages/address/components/EnderecoEscolha";
import axiosInstance from "@/configs/axios";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useUserStore } from "@/pages/user/usecases/index";
import { useCustomerStore } from "@/pages/customer/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import router from "@/router/index";
import ActionEnum from "@/domain/action/ActionEnum";
import NextIdGeneration from "@/domain/NextIdGeneration";
import AddressActions from "@/pages/address/enums/AddressActions";
import { routes } from "@/router/routes";

import * as findAddressToViaCepAction from "@/pages/address/usecases/findAddressToViaCepAction.js";

export const useAddressStore = defineStore("addressStore", {
  state: () => ({
    formData: {
      propriedade: null,
      observacao: null,
      streetAddress: null,
      number: null,
      zip: null,
      district: null,
      addressInformation: null,
      latitude: null,
      longitude: null,
      region: {},
      regionId: null,
      city: {},
      cityId: null,
      person: {},
      personId: null,
      tipoEndereco: 'residencial',
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      if (this.processing) return;

      this.processing = true;
      try {
        await axiosInstance.post(
          routes.address + "/cesta",
          this.$state.formData
        );
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Endereço cadastrado com sucesso!`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async criaEnderecoRural() {
      if (this.processing) return;

      this.processing = true;
      try {
        await axiosInstance.post(routes.enderecoRural, this.$state.formData);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Endereço rural cadastrado com sucesso!`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async findById(id) {
      try {
        const response = await axiosInstance.get(`${routes.address}/${id}`);
        return response.data;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      }
    },

    async updateById() {
      if (this.processing) return;

      this.processing = true;
      const formData = this.$state.formData;
      try {
        await axiosInstance.put(`${routes.address}/${formData.id}`, formData);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Endereço atualizado com sucesso!`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async atualizaEnderecoRuralPorId() {
      if (this.processing) return;

      this.processing = true;
      const formData = this.$state.formData;
      try {
        await axiosInstance.put(
          `${routes.enderecoRural}/${formData.id}`,
          formData
        );
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Endereço rural atualizado com sucesso!`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async deleteAllById(items, fields) {
      if (this.processing) return;

      items.forEach(async (item) => {
        this.processing = true;
        this.isLoading = true;
        try {
          await axiosInstance.delete(`${routes.address}/${item.id}`);
          fields.splice(fields.indexOf(item), 1);
          await this.findPaginate({ page: this.$state.currentPage });
          useSnackBarStore().mostrarSnackBar(
            messageSuccess(`Endereços deletados com sucesso!`)
          );
          this.closeModal = true;
        } catch (e) {
          useSnackBarStore().mostrarSnackBar(messageError(error(e)));
          this.closeModal = false;
        } finally {
          this.isLoading = false;
          this.processing = false;
        }
      });
    },

    async viaCep(fields, vue) {
      await findAddressToViaCepAction.findAddressViaCep(fields, vue);
    },

    setPersonId(id) {
      this.formData.personId = id;
    },

    cleanData() {
      this.formData.propriedade = null;
      this.formData.observacao = null;
      this.formData.streetAddress = null;
      this.formData.number = null;
      this.formData.zip = null;
      this.formData.district = null;
      this.formData.addressInformation = null;
      this.formData.latitude = null;
      this.formData.longitude = null;
      this.formData.region = {};
      this.formData.city = {};
      this.formData.person = {};
      this.formData.tipoEndereco = 'residencial';
    },

    resetIds() {
      this.formData.personId = null;
      this.formData.regionId = null;
      this.formData.cityId = null;
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    isTipoResidencial() {
      return this.formData.tipoEndereco === 'residencial';
    },

    setTipoEnderecoResidencial() {
      return this.formData.tipoEndereco = 'residencial';
    },

    setTipoEnderecoRural() {
      return this.formData.tipoEndereco = 'rural';
    },

    actionCreate() {
      const exeuctavel = () => {
        this.cleanData();
        useDialogStore().openDialogWithData({
          title: "Cria endereço",
          childComponent: EnderecoEscolha,
          isUpdate: false,
        });
      };

      const acao = new Action(
        "Criar endereço",
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(AddressActions.CREATE_ADDRESS),
        new ValidateActionHandler(),
        exeuctavel
      );

      return acao;
    },

    actionEdit() {
      const executavel = (items) => {
        if (items[0].zip !== null && items[0].zip !== undefined) {
          useDialogStore().openDialogWithData({
            title: "Edita endereço residencial",
            childComponent: AddressForm,
            isUpdate: true,
            data: items[0],
          });
        } else {
          useDialogStore().openDialogWithData({
            title: "Edita endereço rural",
            childComponent: EnderecoRuralForm,
            isUpdate: true,
            data: items[0],
          });
        }
      };

      const acao = new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(AddressActions.FIND_ALL_ADDRESS),
        new ValidateEditHandler(),
        executavel
      );

      return acao;
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        this.hasPermission(AddressActions.DELETE_ADDRESS),
        new ValidateDeleteHandler(),
        (items) => {
          const route = router.getRouteFromRouteName(
            router.history.current.name
          );
          if (route.id === "customer") {
            if (this.formData.personId) {
              this.deleteAllById(items, useCustomerStore().formData.address);
            } else {
              items.forEach((item) => {
                const findObject = useCustomerStore().formData.address.find(
                  (element) => element.id === item.id
                );
                useCustomerStore().formData.address.splice(
                  useCustomerStore().formData.address.indexOf(findObject),
                  1
                );
              });
            }
          } else {
            if (this.formData.personId) {
              this.deleteAllById(items, useUserStore().formData.address);
            } else {
              items.forEach((item) => {
                const findObject = useUserStore().formData.address.find(
                  (element) => element.id === item.id
                );
                useUserStore().formData.address.splice(
                  useUserStore().formData.address.indexOf(findObject),
                  1
                );
              });
            }
          }
        }
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(AddressActions.FIND_ALL_ADDRESS),
        new ValidateActionHandler(),
        () => this.findPaginate({ page: this.getCurrentPage })
      );
    },

    actionCreateSave() {
      const novoEnderecoSemSalvar = (item) => {
        let addresses = [];
        const route = router.getRouteFromRouteName(router.history.current.name);
        if (route.id === "customer") {
          addresses = useCustomerStore().formData.address;
          item.id = new NextIdGeneration(addresses).nextId();
          useCustomerStore().addAddress(JSON.parse(JSON.stringify(item)));
        } else {
          addresses = useUserStore().formData.address;
          item.id = new NextIdGeneration(addresses).nextId();
          useUserStore().addAddress(JSON.parse(JSON.stringify(item)));
        }
      };

      const exeuctavel = (item) => {
        item.cityId = item.city?.id;
        item.regionId = item.region?.id;

        if (this.formData.personId) {
          if (this.isTipoResidencial()) {
            this.create();
          } else {
            this.criaEnderecoRural();
          }
        } else {
          novoEnderecoSemSalvar(item);
        }
      };

      const acao = new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(AddressActions.CREATE_ADDRESS),
        new ValidateActionHandler(),
        exeuctavel
      );

      return acao;
    },

    actionEditSave() {
      const executavel = (item) => {
        item.cityId = item.city?.id;
        item.regionId = item.region?.id;
        let findObject = {};
        const route = router.getRouteFromRouteName(router.history.current.name);

        if (route.id === "customer") {
          findObject = useCustomerStore().formData.address.find(
            (element) => element.id === item.id
          );
        } else {
          findObject = useUserStore().formData.address.find(
            (element) => element.id === item.id
          );
        }

        if (!findObject) {
          useSnackBarStore().mostrarSnackBar(
            messageError(
              error(
                "Não foi possível encontrar esse registro, contate o suporte."
              )
            )
          );
          return;
        }

        if (this.formData.personId) {
          if (this.isTipoResidencial()) {
            this.updateById();
          } else {
            this.atualizaEnderecoRuralPorId();
          }
        }
      };

      const acao = new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(AddressActions.UPDATE_ADDRESS),
        new ValidateActionHandler(),
        executavel
      );

      return acao;
    },

    getMenuActions() {
      return [this.actionCreate(), this.actionEdit(), this.actionDelete()];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionCreateSave(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
