import WorkOrderActions from '@/pages/workOrder/enums/workOrderActions'

export const workOrderRouteCreateEnum =
{
  id: 'workOrder',
  path: "/ordem-de-servico/novo",
  name: "workOrder-novo",
  description: "Nova ordem de serviço",
  order: -1,
  component: () => import("@/pages/workOrder/components/WorkOrderForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ WorkOrderActions.CREATE_WORK_ORDER ]
  }
}
