import ReportActions from '@/pages/report/enums/ReportActions'

export const reportRouteListEnum =
{
  id: 'report',
  path: "/relatorios",
  name: "report",
  description: "Relatórios",
  order: 1,
  component: () => import("@/pages/report/ReportPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ReportActions.FIND_ALL_REPORT ]
  }
}
