export default class ReportActions {
    static FIND_REPORT_BY_ID = "FIND_REPORT_BY_ID";
    static FIND_ALL_REPORT = "FIND_ALL_REPORT";
    static SUBMIT_REPORT_PDF = "SUBMIT_REPORT_PDF";
    static CREATE_REPORT = "CREATE_REPORT";
    static UPDATE_REPORT = "UPDATE_REPORT";
    static DELETE_REPORT = "DELETE_REPORT";

    static values() {
        return [
            ReportActions.FIND_REPORT_BY_ID,
            ReportActions.FIND_ALL_REPORT,
            ReportActions.SUBMIT_REPORT_PDF,
            ReportActions.CREATE_REPORT,
            ReportActions.UPDATE_REPORT,
            ReportActions.DELETE_REPORT,
        ];
    }
}

