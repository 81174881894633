import ContactActions from '@/pages/contact/enums/ContactActions'

export const contactRouteListEnum = {
    id: 'contact',
    path: "/contacts",
    name: "contacts",
    description: 'Contatos',
    order: 1,
    component: () => import("@/pages/contact/ContactPage.vue"),
    meta: {
        requiresAuth: true,
        requiresPermissions: [ContactActions.FIND_ALL_CONTACT]
    }
}
