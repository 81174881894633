import { defineStore } from "pinia";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";

import InstallmentForm from "@/pages/installment/components/InstallmentForm.vue";
import DueDateHistoryPage from "@/pages/dueDateHistory/DueDateHistoryPage.vue";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useOrderStore } from "@/pages/order/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import InstallmentActions from "@/pages/installment/enums/InstallmentActions";

import * as findByIdAction from "@/pages/installment/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/installment/usecases/updateByIdAction.js";
import * as deleteAllByIdAction from "@/pages/installment/usecases/deleteAllByIdAction.js";

export const useInstallmentStore = defineStore("installmentStore", {
  state: () => ({
    formData: {
      dueDate: null,
      ordersId: null,
      debtBalance: null,
      valueFormat: null,
      installmentValue: null,
      paymentStatus: null,
      entries: [],
      dueDateHistory: [],
    },
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    authorities: [],
  }),
  actions: {
    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(items, arrayObjects) {
      await deleteAllByIdAction.deleteAllById(this, items, arrayObjects);
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(
        InstallmentActions.FIND_INSTALLMENT_BY_ID
      );
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    cleanData() {
      this.formData.dueDate = null;
      this.formData.debtBalance = null;
      this.formData.valueFormat = null;
      this.formData.installmentValue = null;
      this.formData.paymentStatus = null;
      this.formData.entries = [];
      this.formData.dueDateHistory = [];
    },

    resetIds() {
      this.formData.ordersId = null;
    },

    setOrderId(id) {
      this.formData.ordersId = id;
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(InstallmentActions.UPDATE_INSTALLMENT),
        new ValidateEditHandler(),
        async (items) => {
          useDialogStore().openDialogWithData({
            title: "Edita parcela",
            childComponent: InstallmentForm,
            isUpdate: true,
            data: items[0],
          });
        }
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(InstallmentActions.UPDATE_INSTALLMENT),
        new ValidateActionHandler(),
        (item) => {
          const findObject = useOrderStore().formData.installments.find(
            (element) => element.id === item.id
          );
          if (!findObject) {
            useSnackBarStore().mostrarSnackBar(
              messageError(
                error(
                  "Não foi possível encontrar esse registro, contate o suporte."
                )
              )
            );
            return;
          }
          if (this.formData.ordersId) this.updateById();
        }
      );
    },

    actionOpenDueDateHistory() {
      return new Action(
        "Abre tela de histoórico de vencimentos",
        "mdi-history",
        "openDueDateHistory",
        true,
        new ValidateEditHandler(),
        async () => {
          useDialogStore().openDialogWithData({
            title: "Abre tela de histórico de vencimentos",
            childComponent: DueDateHistoryPage,
            isUpdate: false,
            data: this.formData.dueDateHistory,
          });
        }
      );
    },

    getMenuActions() {
      return [this.actionEdit()];
    },

    getMenuFormActions() {
      return [this.actionOpenDueDateHistory()];
    },

    getActionsListener() {
      return [
        ...this.getMenuActions(),
        ...this.getMenuFormActions(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
