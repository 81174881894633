<template>
  <v-container fluid>
    <v-card>
      <v-toolbar dense flat>
        <Breadcrumb />
        <v-spacer></v-spacer>
        <Menu
          :name="getMenu().name"
          :permission="getMenu().permission"
          :icon="getMenu().icon"
          :color="getMenu().color"
          :outlined="getMenu().outlined"
          :items="getMenu().items"
          @action="(action) => clickMenuAction(action)"
        />
      </v-toolbar>
      <div>
        <div>
          <v-row>
            <v-col cols="12" md="10">
              <v-card-title class="title text-start"
                >Informações da pessoa</v-card-title
              >
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mx-auto w-100 my-2">
            <v-col cols="12" md="4" sm="4">
              <v-text-field
                data-cy="nome-people-field"
                v-model="customerStore.formData.name"
                outlined
                label="Nome"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-text-field
                data-cy="cpfOuCnpj-people-field"
                v-model="customerStore.formData.cpfOuCnpj"
                label="CPF/CNPJ"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="customerStore.formData.type"
                label="Natureza fiscal"
                :items="pessoaNaturezaFiscalOptions"
                item-text="description"
                item-value="cod"
                outlined
                hide-no-data
                append-icon="mdi-file-document"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mx-auto w-100 my-2">
            <v-col cols="12" md="4">
              <v-select
                data-cy="status-note-field"
                v-model="customerStore.formData.status"
                label="Status"
                :items="personStatusOptions"
                item-text="description"
                item-value="cod"
                outlined
              >
              </v-select>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field
                data-cy="email-people-field"
                v-model="customerStore.formData.email"
                label="E-mail"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <div>
          <AddressPage
            :listObjects="customerStore.formData.address"
          ></AddressPage>
        </div>

        <div>
          <ContactPage
            :listObjects="customerStore.formData.contacts"
          ></ContactPage>
        </div>

        <div v-if="isUpdate">
          <OrdersPage :listObjects="customerStore.formData.orders"></OrdersPage>
        </div>
      </div>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="mx-1">
            <Button
              :name="'Cancelar'"
              :handleFunction="cancel"
              :permission="true"
            />
          </div>
          <div class="mx-1 mr-4">
            <Button
              :name="'Salvar'"
              :handleFunction="save"
              :permission="actionFinded.permission"
              :outlined="false"
              :color="'primary'"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { useCustomerStore } from "@/pages/customer/usecases/index";
import { useOrderStore } from "@/pages/order/usecases/index";
import { useAddressStore } from "@/pages/address/usecases/index";
import { useContactStore } from "@/pages/contact/usecases/index";
import { useImageStore } from "@/components/image/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import ActionEnum from "@/domain/action/ActionEnum";

import AddressPage from "@/pages/address/AddressPage.vue";
import ContactPage from "@/pages/contact/ContactPage.vue";
import OrdersPage from "@/pages/order/OrderPage.vue";
import Button from "@/components/Button.vue";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import Menu from "@/components/menu/Menu.vue";

import PersonStatusEnum from "@/pages/enums/PersonStatusEnum";
import PessoaNaturezaFiscalEnum from "@/pages/enums/PessoaNaturezaFiscalEnum";

export default {
  name: "CustomerForm",
  components: {
    AddressPage,
    ContactPage,
    Button,
    OrdersPage,
    Breadcrumb,
    Menu,
  },
  setup() {
    const customerStore = useCustomerStore();
    const orderStore = useOrderStore();
    const addressStore = useAddressStore();
    const contactStore = useContactStore();
    const imageStore = useImageStore();
    const dialogStore = useDialogStore();
    return {
      customerStore,
      orderStore,
      addressStore,
      contactStore,
      imageStore,
      dialogStore,
    };
  },
  data() {
    return {
      fields: useCustomerStore().formData,
      isUpdate: false,
      actionFinded: {},
      listeners: [],
      personStatusOptions: PersonStatusEnum.values(),
      pessoaNaturezaFiscalOptions: PessoaNaturezaFiscalEnum.values(),
    };
  },

  created() {
    let id = this.$route.params.id;
    if (this.isDialogFormAtivo()) {
      id = this.dialogStore.getDialogAtivo?.data?.id;
    }

    if (id) {
      this.loadData(id);
      this.isUpdate = true;
    }

    const action = this.isUpdate
      ? ActionEnum.EDIT_SAVE.action
      : ActionEnum.CREATE_SAVE.action;
    this.listeners = this.customerStore.getActionsListener();
    this.actionFinded = this.listeners.find((a) => a.action === action);
  },
  methods: {
    isDialogFormAtivo() {
      let dialogAtivo = this.dialogStore.getDialogAtivo;
      return dialogAtivo?.childComponent?.name === "CustomerForm";
    },
    async loadData(id) {
      const object = await this.customerStore.findById(id);
      if (!object) return;
      this.setConfigurationsStore(object);
      const customer = JSON.parse(
        JSON.stringify(this.customerStore.getCustomer(object))
      );
      useCustomerStore().formData = customer;
      useOrderStore().setCustomer(customer);
    },
    setConfigurationsStore(object) {
      const personId = object?.person?.id;
      this.addressStore.setPersonId(personId);
      this.contactStore.setPersonId(personId);
      this.imageStore.setPersonId(personId);
    },
    async save() {
      this.actionFinded.execute(useCustomerStore().formData);
    },
    async clickMenuAction(action) {
      const actionFinded = this.listeners.find((a) => a.action === action);
      actionFinded.execute(useCustomerStore().formData);
    },
    cancel() {
      this.isDialogFormAtivo()
        ? this.dialogStore.closeDialog()
        : this.$router.back();
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.isUpdate ? this.customerStore.getMenuFormActions() : [],
      };
    },
  },
};
</script>
