<template>
  <v-container>
    <v-row class="mx-auto w-100 my-2">
      <v-col
        cols="12"
        v-for="parameter in parameters"
        :key="parameter.parametro"
      >
        <component
          v-if="parameter.type !== 'date'"
          :value="fields[parameter.parametro]"
          v-model="fields[parameter.parametro]"
          :rules="getRules(parameter.isMandatory)"
          :is="createField(parameter.type, parameter.parametro)"
          :label="parameter.description"
          outlined
        ></component>
        <template v-else-if="parameter.type === 'date'">
          <v-menu
            v-model="parameter.showDatePicker"
            offset-y
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fields[parameter.parametro]"
                :label="parameter.description"
                :rules="getRules(parameter.isMandatory)"
                readonly
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fields[parameter.parametro]"
              :label="parameter.description"
              :rules="getRules(parameter.isMandatory)"
              locale="pt"
              style="width: 100%"
            ></v-date-picker>
          </v-menu>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex align-center justify-center">
        <v-btn
          color="primary"
          @click="printer()"
          data-cy="report-default-print-btn"
        >
          <v-icon dark> mdi-printer </v-icon> Imprimir
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import { useReportStore } from "@/pages/report/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import {
  VTextField,
  VDatePicker,
  VCheckbox,
  VSelect,
  VMenu,
} from "vuetify/lib";

Vue.component("v-text-field", VTextField);
Vue.component("v-date-picker", VDatePicker);
Vue.component("v-checkbox", VCheckbox);
Vue.component("v-select", VSelect);
Vue.component("v-menu", VMenu);

export default {
  name: "ReportDefault",
  setup() {
    const reportStore = useReportStore();
    return {
      reportStore,
    };
  },
  data() {
    return {
      fields: useDialogStore().data,
      parameters: [],
    };
  },
  methods: {
    printer() {
      const invalidFields = this.isFormDataValid();
      if (invalidFields.length > 0) {
        useSnackBarStore().mostrarSnackBar(
          messageError(
            error(
              "Por favor, preencha todos os campos obrigatórios: \n\n" + invalidFields.join("\n ")
            )
          )
        );
        return;
      }

      const params = {};
      for (const parameter of this.parameters) {
        params[parameter.parametro] = this.fields[parameter.parametro];
      }
      this.reportStore.print(this.fields, params);
    },
    isFormDataValid() {
      let fields = [];
      this.parameters
        .filter((p) => p.isMandatory)
        .forEach((p) => {
          const field = this.fields[p.parametro];
          if (!field) {
            fields.push(p.description);
          }
        });
      return fields;
    },
    createField(type) {
      const components = {
        string: "v-text-field",
        date: "v-date-picker",
        boolean: "v-checkbox",
        int: "v-text-field",
        double: "v-text-field",
      };
      return components[type] || "v-text-field";
    },
    getRules(isMandatory) {
      let rules = [];
      if (isMandatory) rules.push((v) => !!v || "Campo obrigatório");
      return rules;
    },
  },
  created() {
    Object.values(this.fields.params).forEach((param) => {
      const type = param.valueClass
        .substring(param.valueClass.lastIndexOf(".") + 1)
        .toLowerCase();
      this.parameters.push({
        parametro: param.name,
        description: param.description,
        isMandatory: param.propertiesMap.propertyNames.includes("mandatory"),
        type: type,
        showDatePicker: false,
      });
    });
  },
};
</script>
