import BillingRouteActions from "@/pages/billingRoute/enums/BillingRouteActions"

const listagemDeRotaDeEntregaEnum =
{
  id: 'entrega',
  path: "/entregas",
  name: "rota de entrega",
  description: "Rotas de entregas",
  order: 1,
  component: () => import("@/pages/rotaDeEntrega/RotaDeEntregaPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ BillingRouteActions.PROCURA_TODAS_ROTAS_DE_ENTREGA ]
  }
}


export const rotaDeEntregaRotas = {
    listagemDeRotaDeEntregaEnum,
}
