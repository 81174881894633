export default class PaymentActions {
    static SUBMIT_ORDER_RECEIPT_WEB = "SUBMIT_ORDER_RECEIPT_WEB";
    static SUBMIT_ORDER_RECEIPT_MOBILE = "SUBMIT_ORDER_RECEIPT_MOBILE";
    static CREATE_PAYMENT = "CREATE_PAYMENT";
    static UPDATE_PAYMENT = "UPDATE_PAYMENT";
    static DELETE_PAYMENT = "DELETE_PAYMENT";

    static values() {
        return [
            PaymentActions.SUBMIT_ORDER_RECEIPT_WEB,
            PaymentActions.SUBMIT_ORDER_RECEIPT_MOBILE,
            PaymentActions.CREATE_PAYMENT,
            PaymentActions.UPDATE_PAYMENT,
            PaymentActions.DELETE_PAYMENT,
        ];
    }
}

