import workOrderActions from '@/pages/workOrder/enums/workOrderActions'

export const workOrderRouteSearchParametersEnum =
{
  id: 'workOrder',
  path: "/ordem-de-servico/pesquisa",
  name: "workOrder-search-parameters",
  description: "Pesquisa ordem de serviço com parâmetros",
  order: -1,
  component: () => import("@/pages/workOrder/components/WorkOrderFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ workOrderActions.SEARCH_WORK_ORDER ]
  }
}

