import WorkOrderActions from '@/pages/workOrder/enums/workOrderActions'

export const workOrderRouteListEnum =
{
  id: 'workOrder',
  path: "/ordens-de-servicos",
  name: "workOrder",
  description: "Ordens de serviços",
  order: 1,
  component: () => import("@/pages/workOrder/WorkOrderPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ WorkOrderActions.FIND_ALL_WORK_ORDER ]
  }
}
