<template>
  <v-card class="rounded-card">
    <v-toolbar flat>
      <v-toolbar-title>{{ name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="mx-1">
        <Button
          :icon="'mdi-refresh'"
          :handleFunction="() => clickRefreshAction()"
          :permission="refresh"
        />
      </div>
      <div class="mx-1">
        <Menu
          :name="menu?.name"
          :permission="menu?.permission"
          :icon="menu?.icon"
          :color="menu?.color"
          :outlined="menu?.outlined"
          :items="menu?.items"
          @action="(action) => clickMenuAction(action)"
        />
      </div>
    </v-toolbar>
    <v-data-table
      v-model="internalSelectedItems"
      mobile-breakpoint="800"
      multiple-select
      show-select
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :customStrings="customStrings"
      :header-props="headerProps"
      :no-results-text="noResultText"
      :no-data-text="noDataText"
      :footer-props="footerProps"
      :items-per-page="itemsPerPage"
      :server-items-length="totalElements"
      @dblclick:row="(event, item) => dbClickAction([item.item])"
      @click:row="(event, item) => rowClickAction([item.item])"
      @update:options="(pagination) => clickPaginateAction(pagination)"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ value }"
      >
        <div :key="header" v-if="header?.layout?.chip?.permissao">
          <v-chip
            :color="
              header.layout.chip.acao ? header.layout.chip.acao(value) : ''
            "
          >
            {{ header.formatter ? header.formatter(value) || '-' : value || '-'}}
          </v-chip>
        </div>
        <div :key="header" v-else>
          {{ header.formatter ? header.formatter(value) || '-' : value || '-' }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-icon
            v-for="menu in filteredMenuItems"
            :key="menu.id"
            size="large"
            class="me-4"
            @click.stop="clickCardListAction([item], menu.action)"
          >
            {{ menu.icon }}
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Button from "@/components/Button.vue";
import Menu from "@/components/menu/Menu.vue";
import ActionEnum from "@/domain/action/ActionEnum";

export default {
  components: {
    Button,
    Menu,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    refresh: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: Object,
      required: true,
    },
    itemsPerPage: {
      type: Number,
    },
    totalElements: {
      type: Number,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: "",
      internalSelectedItems: [],
      customStrings: {
        rowsPerPageText: "Linhas por página:",
      },
      headerProps: {
        sortByText: "Ordenar por chave",
      },
      noResultText: "Não foram encontrados nenhum dado com essa nomenclatura",
      noDataText: "Dados não encontrados",
      footerProps: {
        "items-per-page-text": "",
        "disable-items-per-page": true,
      },
    };
  },
  watch: {
    selectedItems: {
      handler(newValue) {
        this.internalSelectedItems = newValue;
      },
      immediate: true,
    },
    internalSelectedItems: {
      handler(newValue) {
        this.$emit(
          ActionEnum.ACTION.action,
          newValue,
          ActionEnum.SELECTED.action
        );
      },
      immediate: true,
    },
  },
  computed: {
    filteredMenuItems() {
      return this.menu?.items.filter(
        (menuItem) => menuItem.isVisibleInCardList
      );
    },
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    dbClickAction(item) {
      this.$emit(ActionEnum.ACTION.action, item, ActionEnum.EDIT.action);
    },
    rowClickAction(item) {
      if (!this.isXs) return;
      this.$emit(ActionEnum.ACTION.action, item, ActionEnum.EDIT.action);
    },
    clickMenuAction(action) {
      this.$emit(ActionEnum.ACTION.action, this.internalSelectedItems, action);
    },
    clickCardListAction(item, action) {
      this.$emit(ActionEnum.ACTION.action, item, action);
    },
    clickRefreshAction() {
      this.$emit(ActionEnum.ACTION.action, null, ActionEnum.REFRESH.action);
    },
    clickPaginateAction(pagination) {
      this.$emit(
        ActionEnum.ACTION.action,
        pagination,
        ActionEnum.PAGINATE.action
      );
    },
  },
};
</script>

<style scoped>
/* Estilização específica para o componente */
.rounded-card {
  border-radius: 20px; /* Define o raio do arredondamento das bordas */
}
</style>
