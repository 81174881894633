import ComboActions from '@/pages/combo/enums/comboActions'

export const comboRouteSearchParametersEnum =
{
  id: 'combo',
  path: "/combo/pesquisa",
  name: "combo-search-parameters",
  description: "Pesquisa combo com parâmetros",
  order: -1,
  component: () => import("@/pages/combo/components/ComboFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ComboActions.SEARCH_COMBO ]
  }
}

