export default class RoleActions {
    static FIND_ROLE_BY_ID = "FIND_ROLE_BY_ID";
    static FIND_ALL_ROLE = "FIND_ALL_ROLE";
    static CREATE_ROLE = "CREATE_ROLE";
    static UPDATE_ROLE = "UPDATE_ROLE";
    static DELETE_ROLE = "DELETE_ROLE";

    static values() {
        return [
            RoleActions.FIND_ROLE_BY_ID,
            RoleActions.FIND_ALL_ROLE,
            RoleActions.CREATE_ROLE,
            RoleActions.UPDATE_ROLE,
            RoleActions.DELETE_ROLE,
        ];
    }
}

