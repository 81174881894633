export default class PersonStatusEnum {
    static VAZIO = { cod: null, description: '' };
    static ACTIVE = { cod: 'ACTIVE', description: 'Ativo' };
    static INACTIVE = { cod: 'INACTIVE', description: 'Inativo' };
    static DEFAULTER = { cod: 'DEFAULTER', description: 'Inadimplente' };
    static DISABLED = { cod: 'DISABLED', description: 'Desabilitado' };
  
    static values() {
      return [
        PersonStatusEnum.VAZIO,
        PersonStatusEnum.ACTIVE,
        PersonStatusEnum.INACTIVE,
        PersonStatusEnum.DEFAULTER,
      ];
    }

    static valuesWitDisabled() {
      return [
        PersonStatusEnum.VAZIO,
        PersonStatusEnum.ACTIVE,
        PersonStatusEnum.INACTIVE,
        PersonStatusEnum.DEFAULTER,
        PersonStatusEnum.DISABLED,
      ];
    }
  }

